/** Import helpers section **/
import { useCallback, useState } from 'react';

/** Import services section **/

import { getWorkedHoursReport } from '../../../api/services/activities-services';
import { colaboratorListSelectService } from '../../../api/services/users-services';

const usePageActions = () => {
  const [workedHours, setWorkedHours] = useState([]);
  const [loading, setLoading] = useState(false);
  const [colaborators, setColaborators] = useState([]);

  const getWorkedHoursList = useCallback((params) => {
    setLoading(true);
    getWorkedHoursReport(params)
      .then((response) => {
        setWorkedHours(response?.resume);
      })
      .catch((error) => {
        console.error(
          'ActivitiesSummaryPage - Error requesting workedHours list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getColaboratorsListSelect = useCallback(() => {
    colaboratorListSelectService()
      .then((response) => {
        if (response?.users) setColaborators(response?.users);
      })
      .catch((error) => {
        console.error('UserAddModal - Error getting: ', error);
      });
  }, []);

  return {
    workedHours,
    colaborators,
    loading,
    getWorkedHoursList,
    getColaboratorsListSelect
  };
};

export default usePageActions;
