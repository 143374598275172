/** Import helpers section **/
import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/

import {
  getLiberateActivitiesService,
  blockDayService
} from '../../../api/services/liberate-activities-services';

const usePageActions = () => {
  const [liberateActivities, setLiberateActivities] = useState([]);
  const [nextBlockDate, setNextBlockDate] = useState('');
  const [currentBlockDate, setCurrentBlockDate] = useState('');
  const [loading, setLoading] = useState(false);

  const getLiberateActivitiesList = useCallback(() => {
    setLoading(true);
    getLiberateActivitiesService()
      .then((response) => {
        if (response?.colaborators)
          setLiberateActivities(response?.colaborators);
        if (response?.next_block_day)
          setNextBlockDate(response?.next_block_day);
        if (response?.current_block_day)
          setCurrentBlockDate(response?.current_block_day);
      })
      .catch((error) => {
        console.error(
          'LiberateActivities - Error requesting LiberateActivities list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleOnChangeDate = (row) => {
    setNextBlockDate(row.value);
  };

  const blockDay = useCallback(() => {
    blockDayService({ block_day: nextBlockDate })
      .then((response) => {
        toast.success('Dia de bloqueo actualizado');
        getLiberateActivitiesList();
      })
      .catch((error) => {
        console.error(
          'LiberateActivities - Error requesting LiberateActivities list: ',
          error
        );
      });
  }, [nextBlockDate, getLiberateActivitiesList]);

  return {
    liberateActivities,
    nextBlockDate,
    currentBlockDate,
    loading,
    getLiberateActivitiesList,
    handleOnChangeDate,
    blockDay
  };
};

export default usePageActions;
