/** Import helpers section **/
import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getEffortsService,
  deleteEffortsService,
  getProjectsLightService,
  getWorkPackageLightService,
  getActivitiesLightService
} from '../../../api/services/efforts-services';

const usePageActions = () => {
  const [loading, setLoading] = useState(false);
  const [efforts, setEfforts] = useState([]);
  const [workpackage, setWorkpackage] = useState([]);
  const [activities, setActivities] = useState([]);
  const [projects, setProjects] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState(null);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedWorkPackage, setSelectedWorkPackage] = useState(null);

  const getEfforts = (activity_id) => {
    setLoading(true);
    getEffortsService({ activity_id })
      .then((response) => {
        setEfforts(response?.efforts);
      })
      .catch((error) => {
        console.error(
          'EffortsComponent - Error requesting efforts list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getProjects = useCallback(() => {
    getProjectsLightService()
      .then((response) => {
        if (response?.projects) setProjects(response?.projects);
      })
      .catch((error) => {
        console.error('UserAddModal - Error getting: ', error);
      });
  }, []);

  const getWorkPackages = (project_id) => {
    getWorkPackageLightService({ project_id })
      .then((response) => {
        setWorkpackage(response?.workpackages);
      })
      .catch((error) => {
        console.error(
          'workpackageComponent - Error requesting workpackage list: ',
          error
        );
      })
      .finally(() => {});
  };

  const getActivities = (workpackage_id) => {
    getActivitiesLightService({ workpackage_id })
      .then((response) => {
        setActivities(response?.activities);
      })
      .catch((error) => {
        console.error(
          'workpackageComponent - Error requesting workpackage list: ',
          error
        );
      })
      .finally(() => {});
  };

  const deleteEfforts = (effortId, handleOnModalClose) => {
    deleteEffortsService({ id: effortId })
      .then(() => {
        handleOnModalClose();
        getEfforts(selectedActivities);
        toast.success('Esfuerzo borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el esfuerzo.');
      });
  };
  const handleOnUpdateList = () => {
    getEfforts(selectedActivities);
  };

  const handleOnProjectChange = (data) => {
    const projectId = data.value;
    setSelectedProject(projectId);
    if (selectedWorkPackage) setSelectedWorkPackage(null);
    if (selectedActivities) setSelectedActivities(null);
    getWorkPackages(projectId);
    setEfforts([]);
  };

  const handleOnWorkPackageChange = (data) => {
    const workpackageId = data.value;
    setSelectedWorkPackage(workpackageId);
    if (selectedActivities) setSelectedActivities(null);
    getActivities(workpackageId);
    setEfforts([]);
  };

  const handleOnActivityChange = (data) => {
    const activityId = data.value;
    setSelectedActivities(activityId);
    getEfforts(activityId);
  };

  return {
    loading,
    efforts,
    workpackage,
    activities,
    projects,
    selectedActivities,
    selectedProject,
    selectedWorkPackage,
    getEfforts,
    getProjects,
    getWorkPackages,
    getActivities,
    deleteEfforts,
    handleOnUpdateList,
    handleOnProjectChange,
    handleOnWorkPackageChange,
    handleOnActivityChange
  };
};

export default usePageActions;
