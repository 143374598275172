/** Import helpers section **/
import * as routes from './URL_ROUTES';

/** Import component section **/
import LoginComponent from '../views/Login';
import RegistrationComponent from '../views/Registration';
import EmailValidationComponent from '../views/EmailValidation';
import ChangePasswordRequestComponent from '../views/ChangePasswordRequest';
import ChangePasswordComponent from '../views/ChangePassword';

import UsersComponent from '../views/Users';
import PermissionsComponent from '../views/Permissions';
import RolePermisionsComponent from '../views/RolePermissions';
import ClientsComponent from '../views/Clients';
import ProjectsComponent from '../views/Projects';
import WorkPackageComponent from '../views/WorkPackage';
import ActivitiesComponent from '../views/Acitvities';
import EffortsComponent from '../views/Efforts';
import ActivitiesSummaryComponent from '../views/ActivitiesSummary';
import ClientUserPage from '../views/ClientUsers';
import LiberateActivitiesPage from '../views/LiberateActivities';
import ProjectReportPage from '../views/ProjectReport';
import UserCheckInPage from '../views/UserCheckIn';
import WorkedHoursPage from '../views/WorkedHours';

/*********************************************************************
 ************************  ROUTES CONTENT  ***************************
 *********************************************************************/

export const PUBLIC_ROUTES = [
  {
    path: '/',
    component: LoginComponent
  },
  {
    path: routes.LOGIN_ROUTE,
    component: LoginComponent
  },
  {
    path: routes.REGISTRATION_ROUTE,
    component: RegistrationComponent
  },
  {
    path: routes.EMAIL_VALIDATION_ROUTE + '/:token',
    component: EmailValidationComponent
  },
  {
    path: routes.CHANGE_PASSWORD_REQUEST_ROUTE,
    component: ChangePasswordRequestComponent
  },
  {
    path: routes.CHANGE_PASSWORD_ROUTE + '/:token',
    component: ChangePasswordComponent
  }
];

export const PRIVATE_ROUTES = [
  {
    path: routes.USER_CHECK_IN_ROUTE,
    component: UserCheckInPage
  },
  {
    path: routes.USERS_ROUTE,
    component: UsersComponent
  },
  {
    path: '/',
    component: UsersComponent
  },
  {
    path: routes.PERMISSIONS_ROUTE,
    component: PermissionsComponent
  },
  {
    path: routes.ROLE_PERMISSIONS_ROUTE,
    component: RolePermisionsComponent
  },
  {
    path: routes.CLIENTS_ROUTE,
    component: ClientsComponent
  },
  {
    path: routes.PROJECTS_ROUTE,
    component: ProjectsComponent
  },
  {
    path: routes.WORK_PACKAGE_ROUTE,
    component: WorkPackageComponent
  },
  {
    path: routes.ACTIVITIES_ROUTE,
    component: ActivitiesComponent
  },
  {
    path: routes.EFFORTS_ROUTE,
    component: EffortsComponent
  },
  {
    path: routes.ACTIVITIES_SUMMARY_ROUTE,
    component: ActivitiesSummaryComponent
  },
  {
    path: routes.CLIENT_USERS + '/:id',
    component: ClientUserPage
  },
  {
    path: routes.LIBERATE_ACTIVITIES_ROUTE,
    component: LiberateActivitiesPage
  },
  {
    path: routes.PROJECT_REPORT_ROUTE,
    component: ProjectReportPage
  },
  {
    path: routes.WORKED_HOURS_ROUTE,
    component: WorkedHoursPage
  }
];
