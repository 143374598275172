import { useEffect, useState, useCallback } from 'react';
import { useForm } from 'react-hook-form';

const useModalForm = (defaultValues, rules) => {
  const { setValue, register, handleSubmit, formState } = useForm();
  const [formData, setFormData] = useState(null);
  const [initilized, setInitilized] = useState(false);

  const fieldRegistration = useCallback(
    (key, value, fieldRules) => {
      register(key, { ...fieldRules });
      setValue(key, value, { shouldValidate: true });
    },
    [register, setValue]
  );

  const fieldSearch = useCallback(
    (rulesObject, valuesObject) => {
      for (const property in rulesObject) {
        const fieldRule = rulesObject[property];
        if (fieldRule.hasOwnProperty('required')) {
          var value = null;
          if (valuesObject && valuesObject.hasOwnProperty(property)) {
            value = !!fieldRule?.selector
              ? valuesObject[property][fieldRule?.selector]
              : valuesObject[property];
          }
          fieldRegistration(property, value, fieldRule);
        } else {
          fieldSearch(rulesObject[property], valuesObject[property]);
        }
      }
    },
    [fieldRegistration]
  );

  const rulesFieldsRegistration = useCallback(() => {
    if (!formData || initilized) return;
    fieldSearch(rules, defaultValues);
    setInitilized(true);
  }, [rules, formData, initilized, defaultValues, fieldSearch]);

  useEffect(() => {
    rulesFieldsRegistration();
  }, [rules, formData, rulesFieldsRegistration]);

  useEffect(() => {
    setFormData(defaultValues);
  }, [defaultValues]);

  const handleOnChange = (name, value) => {
    setValue(name, value, { shouldValidate: true });
  };

  const handleOnSubmit = (handleOnSave) => handleSubmit(handleOnSave);

  return {
    handleOnChange,
    handleOnSubmit,
    isValid: formState.isValid
  };
};

export default useModalForm;
