/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { inputChange } from '../../../../utils/formUtils';

/** Import syles section **/
import './ActivityDeleteModalStyles.scss';

/** Import component section **/
import useModalActions from '../hooks/useModalActions';
import ModalComponent from '../../../../components/Modal';
import MUITextField from '../../../../components/inputs/MUITextField';

const ActivityDeleteModal = ({ activityData, onClose, onUpdateList }) => {
  const [activity, setActivity] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  const { deleteActivity } = useModalActions();

  useEffect(() => {
    const newActivity = Object.assign(activityData);
    setActivity(newActivity);
  }, [activityData]);

  useEffect(() => {
    if (!activity) return;
    let validated = false;

    const remaining_effort = activity?.remaining_effort;
    const count_efforts_activity = activity?.count_efforts_activity;
    if (
      (count_efforts_activity > 1 && remaining_effort) ||
      count_efforts_activity === 1
    ) {
      validated = true;
    }

    setDataComplete(validated);
  }, [activity]);

  const handleOnSave = () => {
    deleteActivity(
      activity.activity_id,
      activity.effort_id,
      activity.remaining_effort,
      onUpdateList,
      onClose
    );
  };

  const title = 'Borrar actividad / esfuerzo';

  return (
    <ModalComponent
      open={true}
      title={title}
      element={activity}
      onClose={onClose}
      onConfirm={handleOnSave}
      disabled={!dataComplete}
    >
      <div className="activityDeleteModal">
        <>
          <label>
            Estas Seguro que quieres borrar el esfuerzo/actividad seleccionado?
          </label>
          {activity?.count_efforts_activity > 1 && (
            <MUITextField
              label="Tiempo restante"
              defaultValue={activity?.remaining_effort}
              type="number"
              name="remaining_effort"
              id="remaining_effort"
              onChange={(data) => inputChange(setActivity, data)}
            />
          )}
        </>
      </div>
    </ModalComponent>
  );
};

export default ActivityDeleteModal;
