/** Import react/libraries section **/
import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

/** Import helpers section **/
import { PRIVATE_ROUTES } from '../../config/ROUTES_CONTENT';
import { COLLABORATOR_ROLE, MANAGER_ROLE } from '../../config/APP_CONFIGS';

/** Import styles section **/
import './PrivateContentStyles.scss';

/** Import resources section **/
import { useSelector } from 'react-redux';

/** Import component section **/
import { RouteAccess } from '../../access/AccessControl';

const PrivateContentPage = () => {
  let role = useSelector((store) => store.session.role);
  let checkInRegistered = useSelector(
    (store) => store.session.profile.check_in_registered
  );
  let checkOutRegistered = useSelector(
    (store) => store.session.profile.check_out_registered
  );

  return (
    <Switch>
      <>
        {PRIVATE_ROUTES.map((route, index) => (
          <RouteAccess
            key={index}
            permissionsRequest={route?.path?.split('/:')[0]}
          >
            <Route exact path={route.path} component={route.component} />
            {role === COLLABORATOR_ROLE &&
            (!checkInRegistered || !checkOutRegistered) ? (
              <Redirect to="/check-in" />
            ) : (
              <Redirect to="/activities-summary" />
            )}
            {role === MANAGER_ROLE && <Redirect to="/activities-summary" />}
          </RouteAccess>
        ))}
      </>
      <Redirect to="/" />
    </Switch>
  );
};

export default PrivateContentPage;
