/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { inputChange } from '../../../../utils/formUtils';
import fieldsRules from './helpers/rules';
import useModalForm from '../../../../utils/useModalForm';
import useModalActions from '../hooks/useModalActions';

/** Import styles section **/
import './styles.scss';

/** Import component section **/
import ModalComponent from '../../../../components/Modal';
import MUITextField from '../../../../components/inputs/MUITextField';

const ClientModalComponent = ({ clientData, onClose, onUpdateList }) => {
  const [client, setClient] = useState(null);

  const { handleOnChange, handleOnSubmit, isValid } = useModalForm(
    client,
    fieldsRules
  );

  const { addClient, updateClient } = useModalActions({
    onClose,
    onUpdateList
  });

  useEffect(() => {
    const newClient = Object.assign(clientData);
    setClient(newClient);
  }, [clientData]);

  const handleOnSave = () => {
    if (client?.id) {
      updateClient(client, onClose, onUpdateList);
    } else {
      addClient(client, onClose, onUpdateList);
    }
  };

  const onChangeInput = (data) => {
    const { name, value } = data;
    inputChange(setClient, data);
    handleOnChange(name, value);
  };

  const title = client?.id ? 'Editar cliente' : 'Agregar cliente';
  // disabled = ;
  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={handleOnSubmit(handleOnSave)}
      disabled={!isValid}
    >
      <div className="clientsModal">
        <MUITextField
          label="Nombre"
          defaultValue={client?.name}
          type="text"
          name="name"
          onChange={(data) => onChangeInput(data)}
        />
        <MUITextField
          label="RFC"
          defaultValue={client?.rfc}
          type="text"
          name="rfc"
          onChange={(data) => onChangeInput(data)}
        />
      </div>
    </ModalComponent>
  );
};

export default ClientModalComponent;
