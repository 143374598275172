import * as routes from '../../config/URL_ROUTES';

/** Import resources section **/
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import LockIcon from '@material-ui/icons/Lock';
import GroupIcon from '@material-ui/icons/Group';
import AssignmentIcon from '@material-ui/icons/Assignment';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import EqualizerIcon from '@material-ui/icons/Equalizer';
import HistoryIcon from '@material-ui/icons/History';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import AccessAlarmIcon from '@material-ui/icons/AccessAlarm';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';

/*********************************************************************
 ************************  MENU ITEMS IDS  ***************************
 *********************************************************************/
const USER_MENU = 'users';
const USER_CHECK_IN_MENU = 'check-in';
const CONFIGURATION_MENU = 'configuration';
const PERMISSIONS_MENU = 'permissions';
const PERMISSIONS_CATALOGUE_MENU = 'permissions-catalogue';
const CLIENTS_MENU = 'clients';
const PROJECTS_MENU = 'projects';
const WORK_PACKAGE_MENU = 'work-package';
const ACTIVITIES_MENU = 'activities';
const ACTIVITIES_SUMMARY_MENU = 'activities-summary';
const EFFORTS_MENU = 'efforts';
const PROJECT_REPORT = 'project-report';
const LIBERATE_ACTIVITIES = 'liberate-activities';
const HOURS_WORKED = 'worked-hours';

/*********************************************************************
 **************************  MENU ITEMS  *****************************
 *********************************************************************/

const MENU_ITEMS = [
  {
    id: USER_CHECK_IN_MENU,
    label: 'Check in',
    url: routes.USER_CHECK_IN_ROUTE,
    icon: AccessAlarmIcon
  },
  {
    id: USER_MENU,
    label: 'Usuarios',
    url: routes.USERS_ROUTE,
    icon: PersonIcon
  },
  {
    id: CLIENTS_MENU,
    label: 'Clientes',
    url: routes.CLIENTS_ROUTE,
    icon: GroupIcon
  },
  {
    id: PROJECTS_MENU,
    label: 'Proyectos',
    url: routes.PROJECTS_ROUTE,
    icon: AssignmentIcon
  },
  {
    id: WORK_PACKAGE_MENU,
    label: 'Paquetes',
    url: routes.WORK_PACKAGE_ROUTE,
    icon: BusinessCenterIcon
  },
  {
    id: ACTIVITIES_MENU,
    label: 'Actividades',
    url: routes.ACTIVITIES_ROUTE,
    icon: PlaylistAddCheckIcon
  },
  {
    id: ACTIVITIES_SUMMARY_MENU,
    label: 'Actividades Resumen',
    url: routes.ACTIVITIES_SUMMARY_ROUTE,
    icon: EqualizerIcon
  },
  {
    id: EFFORTS_MENU,
    label: 'Esfuerzos',
    url: routes.EFFORTS_ROUTE,
    icon: AssignmentTurnedInIcon
  },
  {
    id: PROJECT_REPORT,
    label: 'Reporte de proyectos',
    url: routes.PROJECT_REPORT_ROUTE,
    icon: HistoryIcon
  },
  {
    id: LIBERATE_ACTIVITIES,
    label: 'Liberación de actividades',
    url: routes.LIBERATE_ACTIVITIES_ROUTE,
    icon: CheckBoxIcon
  },
  {
    id: HOURS_WORKED,
    label: 'Reporte de horas trabajadas',
    url: routes.WORKED_HOURS_ROUTE,
    icon: HourglassEmptyIcon
  },
  {
    id: CONFIGURATION_MENU,
    label: 'Configuración',
    icon: SettingsIcon,
    items: [
      {
        id: PERMISSIONS_MENU,
        label: 'Permisos por rol',
        url: routes.ROLE_PERMISSIONS_ROUTE,
        icon: AccountTreeIcon
      },
      {
        id: PERMISSIONS_CATALOGUE_MENU,
        label: 'Permisos',
        url: routes.PERMISSIONS_ROUTE,
        icon: LockIcon
      }
    ]
  }
];
export default MENU_ITEMS;
