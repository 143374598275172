/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './NodeDragStyles.scss';

/** Import resources section **/

/** Import component section **/

const NodeDragComponent = (props) => {
  const item = props.monitorProps.item;

  return (
    <div className="nodeDrag">
      <div className="nodeDrag_label">{item.text}</div>
    </div>
  );
};

export default NodeDragComponent;
