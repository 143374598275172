/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import styles section **/
import './SplashStyles.scss';

/** Import resources section **/

/** Import component section **/

const SplashPage = () => {
  return (
    <>
      <div className="splash">
        <div className="splash_container">
          <div className="splash_container_loader"></div>
          <span className="splash_container_text">Cargando...</span>
        </div>
      </div>
    </>
  );
};

export default SplashPage;
