/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  deleteActivityService,
  getProjectsSelectService
} from './../../../api/services/activities-services';
import {
  getEffortsByUserService,
  dragUpdateEffortService
} from './../../../api/services/efforts-services';

import { userCloseDayService } from '../../../api/services/users-services';

const usePageActions = () => {
  const [efforts, setEfforts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [closeDate, setCloseDate] = useState(null);
  const [blockDay, setblockDay] = useState(null);
  const [canEditCloseDay, setCanEditCloseDay] = useState(true);

  const getProjects = useCallback(() => {
    getProjectsSelectService()
      .then((response) => {
        if (response?.projects) setProjects(response?.projects);
      })
      .catch((error) => {
        console.error('UserAddModal - Error getting: ', error);
      });
  }, []);

  const getEffortsByUser = useCallback((date) => {
    getEffortsByUserService(date)
      .then((response) => {
        let effortsArr = response?.efforts;
        let closeDate = response?.close_day;
        let blockDay = response?.block_day;
        let canEdit = response?.edit_close_day || false;

        for (let i = 0; i < effortsArr.length; i++) {
          effortsArr[i].start = new Date(effortsArr[i].start);
          effortsArr[i].end = new Date(effortsArr[i].end);
        }
        if (effortsArr) setEfforts(effortsArr);
        if (closeDate) setCloseDate(closeDate);
        if (blockDay) setblockDay(blockDay);
        setCanEditCloseDay(canEdit);
      })
      .catch((error) => {
        console.error(
          'activitiesComponent - Error requesting efforts list: ',
          error
        );
      });
  }, []);

  const updateDrag = (id, start, end) => {
    dragUpdateEffortService({ id, start, end })
      .then((response) => {
        getEffortsByUser();
      })
      .catch((error) => {
        console.error('activitiesComponent - Error updating activity: ', error);
      })
      .then(() => {});
  };

  const deleteActivity = (
    activityId,
    effortId,
    handleOnUpdateList,
    handleOnModalClose
  ) => {
    deleteActivityService({
      activity_id: activityId,
      effort_id: effortId
    })
      .then(() => {
        handleOnModalClose();
        handleOnUpdateList();
        toast.success('Actividad borrada');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar la actividad.');
      });
  };

  const userCloseDay = (
    closeDayData,
    handleOnUpdateList,
    handleOnModalClose
  ) => {
    userCloseDayService(closeDayData)
      .then(() => {
        handleOnModalClose();
        handleOnUpdateList();
        toast.success('Día cerrado con éxito.');
      })
      .catch((error) => {
        if (error?.response_status === 406) {
          toast.warning(
            'No se pudo cerrar el dia, por favor registre su entrada y salida.'
          );
        } else {
          toast.error('Día no se pudo cerrar éxitosamente.');
        }
      });
  };

  return {
    efforts,
    projects,
    closeDate,
    blockDay,
    canEditCloseDay,
    getProjects,
    getEffortsByUser,
    updateDrag,
    deleteActivity,
    userCloseDay
  };
};

export default usePageActions;
