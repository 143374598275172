/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';

/** Import helpers section **/
import moment from 'moment';
/** Import styles section **/
import './ActivitiesSummaryStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import MUISelect from '../../components/inputs/MUISelect';
import MUIDateField from '../../components/inputs/MUIDateField';

const WorkedHoursPage = () => {
  const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().format('YYYY-MM-DD');

  const [selectedColaborator, setSelectedColaborator] = useState(null);
  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(endOfMonth);

  const {
    workedHours,
    colaborators,
    loading,
    getWorkedHoursList,
    getColaboratorsListSelect
  } = usePageActions();

  useEffect(() => {
    getColaboratorsListSelect();
  }, [getColaboratorsListSelect]);

  useEffect(() => {
    if (startDate && endDate && selectedColaborator) {
      let params = {
        colaborator_id: selectedColaborator,
        start_date: startDate,
        end_date: endDate
      };
      getWorkedHoursList(params);
    }
  }, [selectedColaborator, startDate, endDate, getWorkedHoursList]);

  const handleOnColaboratorChange = (data) => {
    const projectId = data.value;
    setSelectedColaborator(projectId);
  };

  const handleOnStartDateChange = (data) => {
    setStartDate(data.value);
  };

  const handleOnEndDateChange = (data) => {
    setEndDate(data.value);
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        omit: true
      },
      {
        name: 'Usuario',
        selector: (row) => row?.name,
        sortable: true,
        wrap: true,
        center: true
      },
      {
        name: 'Dia',
        selector: (row) => row?.day,
        sortable: true,
        center: true
      },
      {
        name: 'Diferencia (Hrs)',
        selector: (row) => row?.diff,
        sortable: true,
        center: true,
        width: '80px'
      },
      {
        name: 'Entrada / Salida',
        selector: (row) => row?.entry + ' - ' + row?.departature,
        sortable: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <div className="clients">
        <PageTitle title="Reporte de horas trabajadas" />
        <Paper className="g-page-header clients_header">
          <div className="activitiesSummary_selectors">
            <MUIDateField
              label="Fecha inicio"
              value={startDate}
              name="start"
              onChange={handleOnStartDateChange}
            />
            <MUIDateField
              label="Fecha fin"
              value={endDate}
              name="end"
              onChange={handleOnEndDateChange}
            />
            <MUISelect
              label="Colaborador"
              defaultValue={selectedColaborator}
              items={colaborators}
              idSelector="id"
              selector="name"
              noSelectedLabel="Select"
              name="role_id"
              onChange={handleOnColaboratorChange}
            />
          </div>
        </Paper>

        <DataTable
          data={workedHours}
          columns={tableColumns}
          loading={loading}
        />
      </div>
    </>
  );
};

export default WorkedHoursPage;
