/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import styles section **/
import './WorkPackagesStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import WorkPackageModal from './components/WorkPackageModal';
import Modal from '../../components/Modal';
import PageTitle from '../../components/PageTitle';
import MUISelect from '../../components/inputs/MUISelect';
import TreeComponent from './components/Tree';

const workPackageModel = {
  id: null,
  use_case_code: '',
  title: '',
  planned_effort: '',
  project_id: '',
  parent_workpackage_id: '',
  workpackage_status_id: ''
};

const WorkPackagesPage = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedWorkPackage, setSelectedWorkPackage] = useState(null);
  const [modalMode, setModalMode] = useState(null);

  const {
    projects,
    workPackages,
    getProjects,
    getWorkPackages,
    updateTree,
    deleteWorkPackage
  } = usePageActions();

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  const updateWorkPackagesTree = (dragSourceId, dropTargetId) => {
    updateTree(dragSourceId, dropTargetId, selectedProject);
  };

  const handleOnProjectChange = (data) => {
    const projectId = data.value;
    setSelectedProject(projectId);
    getWorkPackages(projectId);
  };

  const handleOnAdd = (node) => {
    let data = Object.assign({}, workPackageModel);
    data.project_id = selectedProject;
    data.parent_workpackage_id = node.id;
    setSelectedWorkPackage(data);
    setModalMode('workpackage');
  };

  const handelOnEdit = (node) => {
    let data = Object.assign({}, node);
    setSelectedWorkPackage(data);
    setModalMode('workpackage');
  };

  const handleOnModalClose = () => {
    setSelectedWorkPackage(null);
    setModalMode(null);
  };

  const handleOnDelete = (id) => {
    setSelectedWorkPackage(id);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteWorkPackage(selectedWorkPackage, selectedProject, handleOnModalClose);
  };

  const handleOnUpdateList = () => {
    getWorkPackages(selectedProject);
  };

  return (
    <>
      <div className="workPackage">
        <PageTitle title="Paquetes de trabajo" />
        <Paper className="g-page-header workPackages_header">
          <div className="workPackages_header_select">
            <MUISelect
              label="Proyecto"
              defaultValue={selectedProject}
              items={projects}
              idSelector="id"
              selector="name"
              noSelectedLabel="Select"
              name="role_id"
              onChange={handleOnProjectChange}
            />
          </div>
        </Paper>
        <Paper>
          <TreeComponent
            workPackages={workPackages}
            onAdd={handleOnAdd}
            onEdit={handelOnEdit}
            onDelete={handleOnDelete}
            onUpdate={updateWorkPackagesTree}
          />
        </Paper>
      </div>
      {selectedWorkPackage && modalMode === 'workpackage' && (
        <WorkPackageModal
          workPackageData={selectedWorkPackage}
          selectedProject={selectedProject}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedWorkPackage && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Cliente"
        >
          {
            '¿Estas seguro que quieres borrar el paquete de trabajo? Eso también borrara todos los paquetes hijos'
          }
        </Modal>
      )}
    </>
  );
};

export default WorkPackagesPage;
