/** Import react/libraries section **/
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';

/** Import styles section **/
import './SignupsStyles.scss';

/** Import resources section **/
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import ConfirmModal from '../../components/ConfirmModal';

const RegisterPage = () => {
  const {
    registered,
    loading,
    registerUser,
    handleOnCancel,
    handleOnConfirmModalClose
  } = usePageActions();

  const handleOnRegister = (userData) => {
    registerUser(userData);
  };
  const { register, handleSubmit } = useForm();

  return (
    <>
      <div className="signups">
        <Paper className="signups_container">
          <h3>Registrar usuario</h3>
          <form
            className="signups_container_form"
            onSubmit={handleSubmit(handleOnRegister)}
          >
            <div className="signups_container_form_fields">
              <TextField
                label="Email"
                className="signups_container_form-email"
                id="email"
                name="email"
                type="text"
                variant="outlined"
                {...register('email', {
                  required: true,
                  message: 'Email required'
                })}
              />
              <span />
              <TextField
                label="Nombre"
                id="first_name"
                name="first_name"
                className="signups_container_form-firstName"
                type="text"
                variant="outlined"
                {...register('first_name', {
                  required: true,
                  message: 'Se requiere nombre'
                })}
              />
              <TextField
                label="Apellido"
                className="signups_container_form-lastName"
                id="lastName"
                name="last_name"
                type="text"
                variant="outlined"
                {...register('last_name', {
                  required: true,
                  message: 'Se requiere apellido'
                })}
              />
              <TextField
                label="Contraseña"
                id="password"
                className="signups_container_form-password"
                name="password"
                type="password"
                variant="outlined"
                {...register('password', {
                  required: true,
                  message: 'Se requiere la contraseña'
                })}
              />
              <TextField
                label="Confirmar contraseña"
                id="passwordConf"
                className="signups_container_form-passwordConf"
                name="password_confirmation"
                type="password"
                variant="outlined"
                {...register('password_confirmation', {
                  required: true,
                  message: 'Se requiere la contraseña'
                })}
              />
            </div>
            <div className="signups_container_form_bottom">
              <div className="signups_container_form_bottom-buttons">
                <Link to="/" underline="hover">
                  <Button onClick={handleOnCancel} className="g-button cancel">
                    Atrás
                  </Button>
                </Link>

                <Button onClick={handleSubmit} type="submit">
                  {loading === true ? 'Salvando...' : 'Registrar'}
                </Button>
              </div>
            </div>
          </form>
        </Paper>
      </div>
      {registered && (
        <ConfirmModal
          title={'Successfully registered!'}
          message={'An email with the verification link was sent.'}
          onClose={handleOnConfirmModalClose}
        />
      )}
    </>
  );
};

export default RegisterPage;
