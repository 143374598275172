/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { inputChange } from '../../../../utils/formUtils';

/** Import styles section **/
import './styles.scss';

/** Import component section **/
import useModalActions from '../hooks/useModalActions';
import ModalComponent from '../../../../components/Modal';
import MUIDateField from '../../../../components/inputs/MUIDateField';
import MUITextField from '../../../../components/inputs/MUITextField';

const LiberateActivitiesModal = ({ userData, onClose, onUpdateList }) => {
  const [user, setUser] = useState(false);
  const [isValid, setIsValid] = useState(false);

  const { updateCloseDay } = useModalActions({
    onClose,
    onUpdateList
  });

  useEffect(() => {
    const newUser = Object.assign(userData);
    setUser(newUser);
  }, [userData]);

  useEffect(() => {
    if (user?.close_day) {
      setIsValid(true);
    }
  }, [user]);

  const handleOnSave = () => {
    if (user?.close_day) {
      let params = {
        user_id: user?.id,
        close_day: user?.close_day
      };

      updateCloseDay(params, onClose, onUpdateList);
    }
  };

  const title = 'Editar fecha de liberación';

  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={handleOnSave}
      disabled={!isValid}
    >
      <div className="clientsModal">
        <MUITextField
          label="Usuario"
          id="user"
          name="name"
          type="text"
          value={user?.name}
          disabled={true}
          onChange={(data) => inputChange(setUser, data)}
        />

        <MUIDateField
          label="Fecha de cierre"
          value={user?.close_day}
          name="close_day"
          onChange={(data) => inputChange(setUser, data)}
        />
      </div>
    </ModalComponent>
  );
};

export default LiberateActivitiesModal;
