import request from '../api-connector';

export const changePasswordRequestService = ({ email }) => {
  const serviceDef = {
    url: 'reset-password-request',
    method: 'post',
    params: { email }
  };
  return request(serviceDef);
};

export const changePasswordService = ({ email, token, password }) => {
  const serviceDef = {
    url: 'change-password',
    method: 'post',
    params: { email, token, password }
  };
  return request(serviceDef);
};
