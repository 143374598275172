import request from '../api-connector';

export const getClientsService = () => {
  const serviceDef = {
    url: 'client/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getClientsSelectService = () => {
  const serviceDef = {
    url: 'client/list/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addClientsService = ({ name, logo_url, rfc }) => {
  const serviceDef = {
    url: 'client/create',
    method: 'post',
    params: { name, logo_url, rfc }
  };
  return request(serviceDef);
};

export const getClientsByIdService = ({ id }) => {
  const serviceDef = {
    url: 'client/read',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};

export const updateClientsService = ({ id, name, logo_url, rfc }) => {
  const serviceDef = {
    url: 'client/update',
    method: 'post',
    params: { id, name, logo_url, rfc }
  };
  return request(serviceDef);
};

export const deleteClientsService = ({ id }) => {
  const serviceDef = {
    url: 'client/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const getRolesService = () => {
  const serviceDef = {
    url: 'role/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const saveClientLogoService = ({ file, id }) => {
  const dataForm = new FormData();
  dataForm.append('file', file);
  dataForm.append('id', id);

  const serviceDef = {
    url: 'client/upload-file',
    method: 'post',
    params: dataForm
  };
  return request(serviceDef);
};

export const getClientUsersService = ({ id }) => {
  const serviceDef = {
    url: 'client/user-list',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const deleteClientUserService = (id) => {
  const serviceDef = {
    url: 'client/user-delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const addClientUserService = ({ client_id, user_id }) => {
  const serviceDef = {
    url: 'client/user-add',
    method: 'post',
    params: { client_id, user_id }
  };
  return request(serviceDef);
};
