/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getRolesService,
  getUsersService,
  deleteUserService,
  changeActiveService,
  changeRegisterService
} from '../../../api/services/users-services';

const usePageActions = () => {
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);

  const getRoles = useCallback(() => {
    getRolesService()
      .then((response) => {
        if (response?.roles) setRoles(response?.roles);
      })
      .catch((error) => {
        console.error('getRoles - Error getting: ', error);
      });
  }, []);

  const getUsers = useCallback(() => {
    getUsersService()
      .then((response) => {
        setUsers(response?.users);
      })
      .catch((error) => {
        console.error('UsersPage - Error requesting users list: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const deleteUser = (id, handleOnUpdateList) => {
    deleteUserService({ id })
      .then(() => {
        handleOnUpdateList();
        toast.success('Usuario borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el usuario.');
      });
  };

  const changeActiveUser = (id) => {
    changeActiveService({ id })
      .then(() => {
        getUsers();
        toast.success('Estatus cambiado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo cambiar el estatus');
      });
  };

  const changeRegisterUser = (id) => {
    changeRegisterService({ id })
      .then(() => {
        getUsers();
        toast.success('Estatus de registro cambiado');
      })
      .catch((error) => {
        toast.error('Error, no se pudo cambiar el estatus de registro');
      });
  };

  return {
    users,
    roles,
    loading,
    getRoles,
    getUsers,
    deleteUser,
    changeActiveUser,
    changeRegisterUser
  };
};

export default usePageActions;
