import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import DialogActions from '@material-ui/core/DialogActions';
import Divider from '@material-ui/core/Divider';
import Button from '@material-ui/core/Button';

const styles = () => ({
	root: {
		backgroundColor: 'white',
		padding: '0 10px 0 10px'
	},
	actions: {
		marginTop: '0',
		display: 'flex',
		justifyContent: 'space-between'
	},
	divider: {
		marginTop: '10px'
	}
});

const MUIDialogActions = withStyles(styles)((props) => {
	const {
		children,
		classes,
		onClose,
		onConfirm,
		onDelete,
		cancelText,
		confirmText,
		deleteText,
		disabled,
		...other
	} = props;
	const handleOnConfirm = (event) => {
		onConfirm({
			preventDefault: () => {},
			persist: () => {}
		});
		event.preventDefault();
	};
	const handleOnDelete = (event) => {
		onDelete({
			preventDefault: () => {},
			persist: () => {}
		});
		event.preventDefault();
	};

	const handleOnClose = (event) => {
		onClose({
			preventDefault: () => {},
			persist: () => {}
		});
		event.preventDefault();
	};

	return (
		<div className={classes.root}>
			<Divider className={classes.divider} />
			<DialogActions className={classes.actions} {...other}>
				<div>
					{onDelete && (
						<Button
							className="g-button delete"
							onClick={handleOnDelete}
						>
							{deleteText ? deleteText : 'Borrar'}
						</Button>
					)}
				</div>
				<div>
					{onClose ? (
						<Button
							className="g-button cancel"
							onClick={handleOnClose}
							style={{ marginRight: '10px' }}
						>
							{cancelText ? cancelText : 'Cancel'}
						</Button>
					) : null}
					{onConfirm ? (
						<Button
							onClick={handleOnConfirm}
							type="submit"
							disabled={disabled ? disabled : false}
						>
							{confirmText ? confirmText : 'Ok'}
						</Button>
					) : null}
				</div>
			</DialogActions>
		</div>
	);
});

export default MUIDialogActions;
