/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';

/** Import helpers section **/
import './usersStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import LockIcon from '@material-ui/icons/Lock';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import NotInterestedIcon from '@material-ui/icons/NotInterested';

/** Import component section **/
import Modal from '../../components/Modal';
import UserModal from './components/UserModal';
import usePageActions from './hooks/usePageActions';
import PageTitle from '../../components/PageTitle';
import FilterBy from '../../components/FilterBy';
import DataTable from '../../components/DataTable';
import usePageFilters from './hooks/usePageFilters';

const userModel = {
  id: null,
  first_name: '',
  last_name: '',
  email: '',
  password: '',
  password_conf: '',
  role_id: null,
  active: 1,
  registered: 0
};

const UsersPage = () => {
  const [filterText, setfilterText] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [modalMode, setModalMode] = useState(null);

  const {
    users,
    roles,
    loading,
    getUsers,
    getRoles,
    deleteUser,
    changeActiveUser,
    changeRegisterUser
  } = usePageActions();

  const { filterList, filteredUsers } = usePageFilters();

  useEffect(() => {
    getRoles();
    getUsers();
  }, [getRoles, getUsers]);

  useEffect(() => {
    filterList(filterText, users);
  }, [filterList, filterText, users]);

  const handleOnUpdateList = () => {
    getUsers();
  };

  const handleOnFilterUsers = (filterVal) => {
    setfilterText(filterVal);
  };

  const handleOnAddUser = () => {
    setSelectedUser(Object.assign({}, userModel));
    setModalMode('user');
  };

  const handleOnChangePassword = (row) => {
    setSelectedUser(row);
    setModalMode('password');
  };

  const handleOnEditUser = (row) => {
    setSelectedUser(row);
    setModalMode('user');
  };

  const handleOnModalClose = () => {
    setSelectedUser(null);
    setModalMode(null);
  };

  const handleOnDeleteUser = (row) => {
    setSelectedUser(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteUser(selectedUser.id, handleOnUpdateList);
    setSelectedUser(null);
    setModalMode(null);
  };

  const handleOnActiveChange = (row) => {
    setSelectedUser(row);
    setModalMode('active');
  };

  const handleOnRegisteredChange = (row) => {
    setSelectedUser(row);
    setModalMode('registered');
  };

  const handleOnActiveConfirm = () => {
    changeActiveUser(selectedUser.id);
    setSelectedUser(null);
    setModalMode(null);
  };

  const handleOnRegisterConfirm = () => {
    changeRegisterUser(selectedUser.id);
    setSelectedUser(null);
    setModalMode(null);
  };

  const ActiveCell = ({ row }) => {
    if (row.active)
      return (
        <CheckCircleOutlineIcon
          className="g-activeicon active users_active"
          onClick={() => handleOnActiveChange(row)}
        />
      );
    return (
      <NotInterestedIcon
        className="g-activeicon users_active"
        onClick={() => handleOnActiveChange(row)}
      />
    );
  };

  const RegisteredCell = ({ row }) => {
    if (row.registered)
      return (
        <CheckCircleOutlineIcon
          className="g-activeicon active users_active"
          onClick={() => handleOnRegisteredChange(row)}
        />
      );
    return (
      <NotInterestedIcon
        className="g-activeicon users_active"
        onClick={() => handleOnRegisteredChange(row)}
      />
    );
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <LockIcon
          className="g-row-action-button"
          onClick={() => handleOnChangePassword(row)}
        />
        <EditIcon
          className="g-row-action-button"
          onClick={() => handleOnEditUser(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => handleOnDeleteUser(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre',
        selector: 'first_name',
        sortable: true,
        center: true
      },
      {
        name: 'Apellido',
        selector: 'last_name',
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: 'email',
        sortable: true,
        center: true,
        wrap: true
      },
      {
        name: 'Rol',
        selector: 'role_title',
        sortable: true,
        center: true
      },
      {
        name: 'Activo',
        selector: 'active',
        cell: (row) => <ActiveCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: 'Registrado',
        selector: 'registered',
        cell: (row) => <RegisteredCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <PageTitle title="Usuarios" />
      <Paper className="g-page-header users_header">
        <div className="users_header_filterby">
          <FilterBy onChange={handleOnFilterUsers} />
        </div>
        <Button onClick={handleOnAddUser} startIcon={<AddCircleIcon />}>
          Agregar nuevo usuario
        </Button>
      </Paper>

      <DataTable
        data={filteredUsers}
        columns={tableColumns}
        loading={loading}
      />

      {selectedUser && (modalMode === 'user' || modalMode === 'password') && (
        <UserModal
          userData={selectedUser}
          modalMode={modalMode}
          roles={roles}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedUser && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Usuario"
        >
          {'¿Estas seguro que quieres borrar el usuario ' +
            selectedUser.email +
            '?'}
        </Modal>
      )}

      {selectedUser && modalMode === 'active' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnActiveConfirm}
          title={
            selectedUser?.active === 0
              ? 'Activar usuario?'
              : 'Desactivar usuario'
          }
        >
          {'¿Estas seguro que quieres ' +
            (selectedUser?.active === 0 ? 'Activar' : 'Desactivar') +
            ' el usuario ' +
            selectedUser.email +
            '?'}
        </Modal>
      )}

      {selectedUser && modalMode === 'registered' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnRegisterConfirm}
          title={
            selectedUser?.registered === 0
              ? 'Habilitar el registro de usuario'
              : 'Desactivar el registro de usuario'
          }
        >
          {'¿Estas seguro que quieres ' +
            (selectedUser?.registered === 0 ? 'activar' : 'desactivar') +
            ' el registro del usuario ' +
            selectedUser.email +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default UsersPage;
