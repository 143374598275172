/** Import react/libraries section **/
import React, { useState, useEffect } from 'react';
import { useHistory, useParams, Link } from 'react-router-dom';

/** Import helpers section **/
import { isValidEmail } from '../../utils/validations.js';
import { LOGIN_ROUTE } from '../../config/URL_ROUTES';
import { inputChange } from '../../utils/formUtils';

/** Import styles section **/
import './ChangePasswordStyles.scss';

/** Import resources section **/
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

/** Import component section **/
import usePageActions from './hooks/usePageActions.jsx';
import ConfirmModal from '../../components/ConfirmModal';
import MUITextField from '../../components/inputs/MUITextField';

const changePasswordModel = {
  email: '',
  password: '',
  confirm_password: ''
};

const ChangePasswordPage = () => {
  const [password, setPassword] = useState(changePasswordModel);
  const [validForm, setValidForm] = useState(false);

  const { requested, loading, successRequest, changePassword } =
    usePageActions();

  const history = useHistory();
  const pageParams = useParams();
  const token = pageParams.token;

  const handleOnSubmit = () => {
    changePassword(password.email, token, password.password);
  };

  useEffect(() => {
    let validEmail = isValidEmail(password.email);
    let validPasswords =
      password.password && password.password_confirm
        ? password?.password?.trim() === password.password_confirm?.trim()
        : false;
    setValidForm(validEmail && validPasswords);
  }, [password.password, password.password_confirm, password.email]);

  const handleOnConfirmModalClose = () => {
    history.push(LOGIN_ROUTE);
  };

  const formView = () => {
    return (
      <>
        <div className="changepassword_container_title">
          Favor de ingresar tu nueva contraseña
        </div>
        <div className="changepassword_container_form">
          <MUITextField
            label="Email"
            id="email"
            name="email"
            type="text"
            value={password?.email}
            onChange={(data) => inputChange(setPassword, data)}
          />
          <MUITextField
            label="Nueva Contraseña"
            id="password"
            name="password"
            type="password"
            value={password?.password}
            onChange={(data) => inputChange(setPassword, data)}
          />
          <MUITextField
            label="Confirmar contraseña"
            id="password_confirm"
            name="password_confirm"
            type="password"
            value={password?.password_confirm}
            onChange={(data) => inputChange(setPassword, data)}
          />
        </div>
        <Button
          className="changepassword_container_button"
          onClick={handleOnSubmit}
          disabled={!validForm}
        >
          {loading === true ? 'Enviando...' : 'Enviar'}
        </Button>
        <div className="changepassword_container_link ">
          <Link to="/">
            <ArrowBackIosIcon /> Regresar a Login
          </Link>
        </div>
      </>
    );
  };

  const errorView = () => {
    return (
      <>
        <span className="changepassword_container_title error">
          Esta solicitud ya no es valida!
        </span>
      </>
    );
  };

  const viewToShow = () => {
    if (requested && !successRequest) {
      return errorView();
    }

    return formView();
  };

  return (
    <>
      {/* <ChangePasswordView
        loading={loading}
        email={email}
        password={password}
        passwordConf={passwordConf}
        onEmailChange={handeOnEmailChange}
        onPasswordChange={handeOnPasswordChange}
        onPasswordConfChange={handeOnPasswordConfChange}
        validForm={validForm}
        onSubmit={handleOnSubmit}
        requested={requested}
        successRequest={successRequest}
      /> */}
      <div className="changepassword">
        <Paper className="changepassword_container">{viewToShow()}</Paper>
      </div>
      {successRequest && (
        <ConfirmModal
          title={'Password successfully changed!'}
          message={'Go to login again.'}
          onClose={handleOnConfirmModalClose}
        />
      )}
    </>
  );
};

export default ChangePasswordPage;
