/** Import helpers section **/
import { useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/

import {
  getClientsService,
  deleteClientsService,
  saveClientLogoService
} from '../../../api/services/clients-services';
const usePageActions = () => {
  const getClientList = useCallback((setClients, setOriginalData) => {
    getClientsService()
      .then((response) => {
        if (response.clients) {
          setClients(response.clients);
          setOriginalData(response.clients);
        }
      })
      .catch(() => {
        toast.warning('Error getting client list');
      });
  }, []);

  const deleteClient = (id, onClose, onUpdateList) => {
    deleteClientsService(id, onClose, onUpdateList)
      .then(() => {
        toast.success('Client deleted correctly');
        onUpdateList();
        onClose();
      })
      .catch(() => {
        toast.warning('Client delete error');
      });
  };

  const saveClientLogo = (data, onClose, onUpdateList) => {
    saveClientLogoService(data, onClose, onUpdateList)
      .then(() => {
        toast.success('Image saved correctly');
        onUpdateList();
        onClose();
      })
      .catch(() => {
        toast.warning('Image save error');
      });
  };

  return {
    getClientList,
    deleteClient,
    saveClientLogo
  };
};

export default usePageActions;
