/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/

import {
  userCheckInListService,
  userCheckInDeleteService
} from '../../../api/services/check-in-services';

const usePageActions = () => {
  const [checkInData, setCheckInData] = useState([]);
  const [loading, setLoading] = useState(false);

  const userCheckInList = useCallback((params) => {
    setLoading(true);
    userCheckInListService(params)
      .then((response) => {
        setCheckInData(response);
      })
      .catch((error) => {
        console.error('Check in list - Error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const userCheckInDelete = useCallback((id, onClose, handleOnUpdateList) => {
    setLoading(true);
    userCheckInDeleteService(id)
      .then((response) => {
        toast.success('Check in borrado correctamente');
        handleOnUpdateList();
        onClose();
      })
      .catch((error) => {
        console.error('Check in delete - Error', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return {
    checkInData,
    loading,
    userCheckInList,
    userCheckInDelete
  };
};

export default usePageActions;
