/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';

/** Import resources section **/
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

/** Import styles section **/

/** Import helpers section **/

/** Import component section **/

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '6px',
    height: '36px',
    fontSize: '12px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: theme.palette.secondary.main
    }
  },
  label: {
    color: 'white',
    minWidth: 'max-content'
  }
}));

export const AddButton = ({ label = 'Add', onClick, disabled, ...other }) => {
  const classes = useStyles();

  return (
    <Button
      classes={{
        root: classes.root,
        label: classes.label
      }}
      startIcon={<AddCircleIcon />}
      onClick={onClick}
      disabled={disabled}
      {...other}
    >
      {label}
    </Button>
  );
};

AddButton.propTypes = {
  label: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default AddButton;
