import { useCallback, useState } from 'react';

const usePageFilters = () => {
  const [filteredActivities, setFilteredActivities] = useState([]);

  const filterList = useCallback((filterVal, data) => {
    let filteredItems;

    if (!filterVal || !filterVal.length) {
      filteredItems = [...data];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = data.filter((item) => {
        const field1 = item?.name?.toString().toLowerCase();
        return field1.match(filteringText);
      });
    }

    setFilteredActivities(filteredItems);
  }, []);

  return {
    filterList,
    filteredActivities
  };
};

export default usePageFilters;
