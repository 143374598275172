/** Import react/libraries section **/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  menuOpen: false
};

const globalsSlice = createSlice({
  name: 'globals',
  initialState: initialState,
  reducers: {
    setMenuOpen: (state, { payload: { open } }) => {
      state.menuOpen = open;
    }
  }
});
export default globalsSlice.reducer;

export const { setMenuOpen } = globalsSlice.actions;
