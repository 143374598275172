/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

/** Import helpers section **/

/** Import styles section **/
import './PermissionsStyles.scss';

/** Import component section **/
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import Modal from '../../components/Modal';
import PermissionsModal from './components/PermissionModal';
import usePageActions from './hooks/usePageActions';

const permissionModel = {
  id: null,
  code: '',
  last_name: '',
  type: ''
};

const PermissionsPage = () => {
  const [selectedPermission, setSelectedPermission] = useState(null);
  const [modalMode, setModalMode] = useState(null);

  const { permissions, loading, getPermissions, deletePermission } =
    usePageActions();

  useEffect(() => {
    getPermissions();
  }, [getPermissions]);

  const handleOnUpdateList = () => {
    getPermissions();
  };

  const handleOnModalClose = () => {
    setSelectedPermission(null);
    setModalMode(null);
  };

  const handleOnAddPermission = () => {
    setSelectedPermission(Object.assign({}, permissionModel));
    setModalMode('add');
  };

  const handelOnDeletePermission = (row) => {
    setSelectedPermission(row);
    setModalMode('delete');
  };

  const handleOnEditPermission = (row) => {
    setSelectedPermission(row);
    setModalMode('edit');
  };

  const handleOnDeleteConfirm = () => {
    deletePermission(selectedPermission.id);
    setSelectedPermission(null);
    setModalMode(null);
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          className="g-row-action-button"
          onClick={() => handleOnEditPermission(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => handelOnDeletePermission(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: (row) => row?.id,
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Código',
        selector: (row) => row?.code,
        sortable: true,
        center: true
      },
      {
        name: 'Tipo',
        selector: (row) => row?.type_name,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  const editModalMode = modalMode === 'edit' || modalMode === 'add';

  return (
    <>
      <div className="permissions">
        <PageTitle title="Permisos" />
        <Paper className="g-page-header permissions_header">
          <Button
            className="permissions_header_button"
            onClick={handleOnAddPermission}
            startIcon={<AddCircleIcon />}
          >
            Agregar nuevo permiso
          </Button>
        </Paper>

        <DataTable
          data={permissions}
          columns={tableColumns}
          loading={loading}
        />
      </div>
      {selectedPermission && editModalMode && (
        <PermissionsModal
          permissionData={selectedPermission}
          modalMode={modalMode}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedPermission && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Usuario"
        >
          {'¿Estas seguro que quieres borrar el usuario el permiso ' +
            selectedPermission.code +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default PermissionsPage;
