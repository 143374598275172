/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import hepers section **/
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';

/** Import resources section **/

/** Import styles section **/
import './DropFile.scss';

/** Import components section **/
import Modal from '../Modal';

function DropFile({
	open = false,
	title = 'Load file',
	url,
	maxFiles = 1,
	onClose,
	onConfirm,
	children
}) {
	const [files, setFiles] = useState([]);
	const { getRootProps, getInputProps } = useDropzone({
		accept: 'image/*',
		onDrop: (acceptedFiles) => {
			setFiles(
				acceptedFiles.map((file) =>
					Object.assign(file, {
						preview: URL.createObjectURL(file)
					})
				)
			);
		}
	});

	const downloadImage = (src) => {
		window.open(src, 'Image');
	};

	const ImageItem = ({ src, name }) => {
		return (
			<div
				className="drop-file_items_item"
				onClick={(e) => downloadImage(src)}
			>
				<div className="drop-file_items_item_image">
					<img
						src={src}
						alt={name}
						className="drop-file_items_item_image_img"
					/>
				</div>
			</div>
		);
	};

	const items = files.map((file) => (
		<div className="drop-file_items_item" key={file.name}>
			<ImageItem src={file.preview} name={file.name} />
			<div className="drop-file_items_item_file">
				<div className="drop-file_items_item_file_path">
					{' '}
					{file.path}{' '}
				</div>
				<div className="drop-file_items_item_file_size">
					{new Intl.NumberFormat().format(file.size)} bytes
				</div>
			</div>
		</div>
	));

	useEffect(
		() => () => {
			files.forEach((file) => URL.revokeObjectURL(file.preview));
		},
		[files]
	);

	const handleOnConfirm = () => {
		onConfirm(files);
	};

	return (
		<Modal
			open={open}
			onConfirm={handleOnConfirm}
			onClose={onClose}
			title={title}
		>
			<section className="drop-file">
				<div {...getRootProps({ className: 'drop-file_zone' })}>
					<input {...getInputProps()} />
					<div>Arrastra aquí el archivo</div>
				</div>
				<div className="drop-file_items">
					{files.length > 0 && items}
					{files.length === 0 && url && (
						<ImageItem src={url} name="file" />
					)}
				</div>
			</section>
			{children}
		</Modal>
	);
}

DropFile.propTypes = {
	open: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
	url: PropTypes.string,
	maxFiles: PropTypes.number,
	onClose: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired,
	children: PropTypes.object
};

export default DropFile;
