import request from '../api-connector';

export const getProjectsSelectService = () => {
  const serviceDef = {
    url: 'project/list/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};
export const getWorkPackagesSelectService = ({ project_id }) => {
  const serviceDef = {
    url: 'workpackage/list/select',
    method: 'get',
    params: { project_id }
  };
  return request(serviceDef);
};

export const getActivitiesService = ({ workpackage_id }) => {
  const serviceDef = {
    url: 'activity/list',
    method: 'get',
    params: { workpackage_id }
  };
  return request(serviceDef);
};

export const getAllActivitiesService = () => {
  const serviceDef = {
    url: 'activity/list/all',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getActivitiesSelectService = ({ workpackage_id }) => {
  const serviceDef = {
    url: 'activity/list/select',
    method: 'get',
    params: { workpackage_id }
  };
  return request(serviceDef);
};
export const getAllByProjectActivitiesService = ({ project_id }) => {
  const serviceDef = {
    url: 'activity/list/allByProject',
    method: 'get',
    params: { project_id }
  };
  return request(serviceDef);
};
export const getActivityStatusService = () => {
  const serviceDef = {
    url: 'activity/list/status',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addActivityService = ({
  title,
  start,
  end,
  workpackage_id,
  activity_id,
  activity_status_id,
  remaining_effort
}) => {
  const serviceDef = {
    url: 'activity/create',
    method: 'post',
    params: {
      title,
      start,
      end,
      workpackage_id,
      activity_id,
      activity_status_id,
      remaining_effort
    }
  };
  return request(serviceDef);
};

export const getActivitiesByIdService = ({ id }) => {
  const serviceDef = {
    url: 'activity/read',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};
export const updateActivityService = ({
  id,
  title,
  start,
  end,
  workpackage_id,
  activity_id,
  activity_status_id,
  remaining_effort
}) => {
  const serviceDef = {
    url: 'activity/update',
    method: 'post',
    params: {
      id,
      title,
      start,
      end,
      workpackage_id,
      activity_id,
      activity_status_id,
      remaining_effort
    }
  };
  return request(serviceDef);
};

export const dragUpdateService = ({ id, start, end }) => {
  const serviceDef = {
    url: 'activity/update/drag-activity',
    method: 'post',
    params: { id, start, end }
  };
  return request(serviceDef);
};

export const deleteActivityService = ({
  activity_id,
  effort_id,
  remaining_effort
}) => {
  const serviceDef = {
    url: 'activity/delete',
    method: 'post',
    params: { activity_id, effort_id, remaining_effort }
  };
  return request(serviceDef);
};
export const getActivitiesSummaryService = ({
  project_id,
  colaborator_id,
  start_date,
  end_date
}) => {
  const serviceDef = {
    url: 'activity/list/summary',
    method: 'get',
    params: { project_id, colaborator_id, start_date, end_date }
  };
  return request(serviceDef);
};

export const getWorkedHoursReport = ({
  colaborator_id,
  start_date,
  end_date
}) => {
  const serviceDef = {
    url: 'activity/worked-hours/list',
    method: 'get',
    params: { colaborator_id, start_date, end_date }
  };
  return request(serviceDef);
};
