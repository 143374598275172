/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { inputChange } from '../../../../utils/formUtils';

/** Import syles section **/
import './PermissionsModalStyles.scss';

/** Import component section **/
import ModalComponent from '../../../../components/Modal';
import MUITextField from '../../../../components/inputs/MUITextField';
import MUISelect from '../../../../components/inputs/MUISelect';
import useModalActions from './hooks/useModalActions';

const typesNames = [
  { id: 1, name: 'Routes' },
  { id: 2, name: 'Menu' },
  { id: 3, name: 'Actions' }
];

const PermissionModal = ({
  permissionData,
  modalMode,
  onClose,
  onUpdateList
}) => {
  const [permission, setPermission] = useState(null);

  const { createPermission, updatePermission } = useModalActions();

  useEffect(() => {
    const newPermission = Object.assign(permissionData);
    setPermission(newPermission);
  }, [permissionData]);

  const handleCallback = () => {
    onUpdateList();
    onClose();
  };

  const handleOnSave = () => {
    if (permission.id) {
      updatePermission(permission, handleCallback);
    } else {
      createPermission(permission, handleCallback);
    }
  };

  const title = permission?.id ? 'Editar Permiso' : 'Agregar Permiso';

  const validCode = !!permission?.code?.toString().trim();
  const validType = !!permission?.type?.toString().trim();
  const valid = validCode && validType;

  return (
    <ModalComponent
      open={true}
      title={title}
      element={permission}
      onClose={onClose}
      onConfirm={handleOnSave}
      disabled={!valid}
    >
      <div className="permissionModal">
        <MUITextField
          label="Acción"
          defaultValue={permission?.code}
          type="text"
          name="code"
          onChange={(data) => inputChange(setPermission, data)}
        />
        <MUISelect
          label="Tipo"
          defaultValue={permission?.type}
          items={typesNames}
          idSelector="id"
          selector="name"
          noSelectedLabel="Select"
          name="type"
          onChange={(data) => inputChange(setPermission, data)}
        />
      </div>
    </ModalComponent>
  );
};

export default PermissionModal;
