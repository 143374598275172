/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  addActivityService,
  updateActivityService,
  getActivitiesSelectService,
  getWorkPackagesSelectService,
  getActivityStatusService,
  deleteActivityService
} from '../../../../api/services/activities-services';

const useModalActions = () => {
  const [activities, setActivities] = useState([]);
  const [workpackages, setWorkpackages] = useState([]);
  const [activityStatus, setActivityStatus] = useState();

  const getActivityStatus = useCallback(() => {
    getActivityStatusService()
      .then((response) => {
        if (response?.activityStatus)
          setActivityStatus(response?.activityStatus);
      })
      .catch((error) => {
        console.error(
          'activitiesComponent - Error requesting activities status list: ',
          error
        );
      });
  }, []);

  const createActivity = (activity, onUpdateList, onClose) => {
    addActivityService(activity)
      .then((response) => {
        toast.success('Activity created');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Activity create error');
      });
  };

  const updateActivity = (activity, onUpdateList, onClose) => {
    updateActivityService(activity)
      .then(() => {
        toast.success('Activity updated');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Activity update error');
      });
  };

  const getWorkPackages = useCallback((project_id) => {
    getWorkPackagesSelectService({ project_id })
      .then((response) => {
        setWorkpackages(response?.workpackages);
      })
      .catch((error) => {
        console.error(
          'workpackageComponent - Error requesting workpackage list: ',
          error
        );
      });
  }, []);

  const getActivities = useCallback((workpackage_id) => {
    getActivitiesSelectService({ workpackage_id })
      .then((response) => {
        setActivities(response?.activities);
      })
      .catch((error) => {
        console.error(
          'workpackageComponent - Error requesting workpackage list: ',
          error
        );
      })
      .finally(() => {});
  }, []);

  const deleteActivity = (
    activityId,
    effortId,
    remainingEffort,
    onUpdateList,
    onClose
  ) => {
    deleteActivityService({
      activity_id: activityId,
      effort_id: effortId,
      remaining_effort: remainingEffort
    })
      .then((response) => {
        toast.success('Actividad borrada');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Error borrando actividad');
      });
  };

  return {
    activities,
    workpackages,
    activityStatus,
    getActivityStatus,
    createActivity,
    updateActivity,
    getWorkPackages,
    getActivities,
    deleteActivity
  };
};

export default useModalActions;
