/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';

/** Import helpers section **/
import moment from 'moment';
/** Import styles section **/
import './ActivitiesSummaryStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import VisibilityIcon from '@material-ui/icons/Visibility';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import MUISelect from '../../components/inputs/MUISelect';
import MUIDateField from '../../components/inputs/MUIDateField';
import ActivityEffortsModal from './components/ActivityEffortsModal';

const ActivitiesSummaryPage = () => {
  const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().format('YYYY-MM-DD');

  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedColaborator, setSelectedColaborator] = useState(null);
  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(endOfMonth);
  const [efforts, setEfforts] = useState([]);
  const [activityTitle, setActivityTitle] = useState('');
  const [modalMode, setModalMode] = useState(null);

  const {
    activitiesSummary,
    projects,
    colaborators,
    loading,
    getActivitiesSummary,
    getProjects,
    getColaboratorsListSelect
  } = usePageActions();

  useEffect(() => {
    getProjects();
    getColaboratorsListSelect();
  }, [getProjects, getColaboratorsListSelect]);

  useEffect(() => {
    if (!!selectedColaborator && (startDate || endDate)) {
      let params = {
        project_id: selectedProject,
        colaborator_id: selectedColaborator,
        start_date: startDate,
        end_date: endDate
      };
      getActivitiesSummary(params);
    }
  }, [
    selectedProject,
    selectedColaborator,
    startDate,
    endDate,
    getActivitiesSummary
  ]);

  const handleOnProjectChange = (data) => {
    const projectId = data.value;
    setSelectedProject(projectId);
  };

  const handleOnColaboratorChange = (data) => {
    const colaboratorId = data.value;
    setSelectedColaborator(colaboratorId);
  };

  const handleOnStartDateChange = (data) => {
    setStartDate(data.value);
  };

  const handleOnEndDateChange = (data) => {
    setEndDate(data.value);
  };

  const onClose = () => {
    setEfforts(null);
    setActivityTitle('');
    setModalMode(null);
  };

  const handleOnViewEfforts = (row) => {
    setEfforts(row?.efforts);
    setActivityTitle(row?.efforts[0]?.['activity_title']);
    setModalMode('efforts');
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <VisibilityIcon
          className="g-row-action-button check"
          onClick={() => handleOnViewEfforts(row)}
        />
      </>
    );
  };

  const WorkpackageCell = ({ row }) => {
    const string = row?.workpackage;
    // var arr = string.split('/');
    var arr = string.match(/[^/]+/g); // se uso expresiones regulares para separar por diagonal como se indica aqui [^/] y posteriormente no guardar string vacio en una posicion del areglo
    var indexOfCurrentWorkpackage = arr.length - 1;
    const currentWorkpackageName = arr[indexOfCurrentWorkpackage];
    // se clona el array sin la ultima posicion para el arreglo de los padres
    var parentsArr = arr.slice();
    parentsArr.splice(indexOfCurrentWorkpackage, 1); // se junta el texto de los padres del array
    parentsArr.splice(0, 1);
    var parentsPackage = parentsArr.join(' / ');

    return (
      <div className="activitiesSummary_fathersWorkpackage">
        <div className="activitiesSummary_fathersWorkpackage_current">
          {currentWorkpackageName}
        </div>
        <div className="activitiesSummary_fathersWorkpackage_father">
          {parentsPackage}
        </div>
      </div>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        omit: true
      },
      {
        name: 'Proyecto',
        selector: (row) => row?.project,
        sortable: true,
        wrap: true,
        left: true
      },
      {
        name: 'Paquete de trabajo',
        cell: (row) => <WorkpackageCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        left: true,
        selector: (row) => row?.workpackage
      },
      {
        name: 'Actividad',
        selector: (row) => row?.activity,
        sortable: true,
        wrap: true,
        left: true
      },
      {
        name: 'Esfuerzos',
        selector: (row) => row?.total_efforts,
        sortable: true,
        center: true,
        width: '80px'
      },
      {
        name: 'Horas',
        selector: (row) => row?.total_hours_per_activity,
        sortable: true,
        center: true,
        width: '80px'
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <div className="clients">
        <PageTitle title="Resumen de actividades" />
        <Paper className="g-page-header clients_header">
          <div className="activitiesSummary_selectors">
            <MUISelect
              label="Colaborador"
              defaultValue={selectedColaborator}
              items={colaborators}
              idSelector="id"
              selector="name"
              noSelectedLabel="Select"
              name="colaborator"
              onChange={handleOnColaboratorChange}
            />
            <MUIDateField
              label="Fecha inicio"
              value={startDate}
              name="start"
              onChange={handleOnStartDateChange}
            />
            <MUIDateField
              label="Fecha fin"
              value={endDate}
              name="end"
              onChange={handleOnEndDateChange}
            />
            <MUISelect
              label="Proyecto"
              defaultValue={selectedProject}
              items={projects}
              idSelector="id"
              selector="name"
              noSelectedLabel="Select"
              name="role_id"
              onChange={handleOnProjectChange}
              disabled={!selectedColaborator}
            />
          </div>
        </Paper>
        <div className="activitiesSummary_totalHours">
          Total de esfuerzos (hrs) :
          {activitiesSummary.length > 0
            ? ' ' +
              activitiesSummary
                .map((o) => o.total_hours_per_activity)
                .reduce((a, c) => a + c)
            : ''}
        </div>

        <DataTable
          data={activitiesSummary}
          columns={tableColumns}
          loading={loading}
          showDownload={true}
        />
      </div>
      {efforts && modalMode === 'efforts' && (
        <ActivityEffortsModal
          efforts={efforts}
          activityTitle={activityTitle}
          onClose={onClose}
        />
      )}
    </>
  );
};

export default ActivitiesSummaryPage;
