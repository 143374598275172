/*********************************************************************
 ***********************  ROUTES DEFINITION  *************************
 *********************************************************************/

// Public routes
export const LOGIN_ROUTE = '/login';
export const REGISTRATION_ROUTE = '/registration';
export const EMAIL_VALIDATION_ROUTE = '/register-verify';
export const CHANGE_PASSWORD_REQUEST_ROUTE = '/change-password-request';
export const CHANGE_PASSWORD_ROUTE = '/change-password';

// Private routes
export const USERS_ROUTE = '/users';
export const ROLE_PERMISSIONS_ROUTE = '/permissions';
export const PERMISSIONS_ROUTE = '/permissions-catalogue';
export const CLIENTS_ROUTE = '/clients';
export const PROJECTS_ROUTE = '/projects';
export const WORK_PACKAGE_ROUTE = '/work-package';
export const ACTIVITIES_ROUTE = '/activities';
export const ACTIVITIES_SUMMARY_ROUTE = '/activities-summary';
export const EFFORTS_ROUTE = '/efforts';
export const PROJECT_REPORT_ROUTE = '/project-report';
export const CLIENT_USERS = '/client-users';
export const LIBERATE_ACTIVITIES_ROUTE = '/liberate-activities';
export const USER_CHECK_IN_ROUTE = '/check-in';
export const WORKED_HOURS_ROUTE = '/worked-hours';
