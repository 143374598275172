/** Import react/libraries section **/
import React, { useEffect, useState, useCallback } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop';
import moment from 'moment';

/** Import helpers section  **/
import { CLOSE_DAY_EDIT } from '../../access/PERMISSIONS';
import { HasPermission } from '../../access/AccessControl';

/** Import styles section **/
import './ActivitiesStyles.scss';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import PageTitle from '../../components/PageTitle';
import EventWrapper from '../../components/EventWrapper';
import ActivityModal from './components/ActivityModal';
import ActivityDeleteModal from './components/ActivityDeleteModal';
import Modal from '../../components/Modal';
import MUIDateField from '../../components/inputs/MUIDateField';

const activityModel = {
  id: null,
  title: '',
  start: null,
  end: null,
  workpackage_id: null,
  activity_id: null,
  project_id: null,
  effort_id: null,
  activity_status_id: null,
  remaining_effort: null,
  duration: null,
  count_efforts_activity: null
};

const ActivitiesPage = () => {
  const CLOSE_DAY_USER_PERMISSION = HasPermission(CLOSE_DAY_EDIT);
  const [modalMode, setModalMode] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);
  const [selectedView, setSelectedView] = useState('day');
  const [selectedDate, setselectedDate] = useState(
    moment().format('YYYY-MM-DD')
  );
  const [closeDay, setCloseDay] = useState(false);
  const [currentRangeDate, setCurrentRangeDate] = useState({
    start: selectedDate,
    end: selectedDate
  });

  const {
    efforts,
    projects,
    closeDate,
    blockDay,
    canEditCloseDay,
    getProjects,
    getEffortsByUser,
    updateDrag,
    deleteActivity,
    userCloseDay
  } = usePageActions();

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  useEffect(() => {
    getEffortsByUser(currentRangeDate);
  }, [getEffortsByUser, currentRangeDate]);

  const handleOnSelectTimeRange = ({ start, end }) => {
    //create

    /** Revisaremos si la fecha que se quiere seleccionar es menor a la fecha de cierre de actividades**/
    if (moment(start).format('YYYY-MM-DD') <= closeDate) {
      return;
    }

    let data = Object.assign({}, activityModel);
    data.start = start;
    data.end = end;
    setSelectedActivity(data);
    setModalMode('activity');
  };

  /** DISABLED DAYS **/
  useEffect(() => {
    setCloseDay(closeDate >= selectedDate ? true : false);
  }, [closeDate, selectedDate]);

  useEffect(() => {
    if (selectedView === 'day') {
      setCurrentRangeDate((prevState) => ({
        ...prevState,
        start: selectedDate,
        end: selectedDate
      }));
    }
  }, [selectedView, selectedDate]);

  const handleOnSelectActivity = (event) => {
    //edit

    /** Revisaremos si la fecha que se quiere seleccionar es menor a la fecha de cierre de actividades**/
    if (moment(event.start).format('YYYY-MM-DD') <= closeDate) {
      return;
    }

    let data = Object.assign({}, activityModel);

    data.start = event.start;
    data.end = event.end;
    data.workpackage_id = event.workpackage.id;
    data.activity_id = event.activity_id;
    data.id = event.activity_id;
    data.project_id = event.project.id;
    data.title = event.title;
    data.effort_id = event.id;
    data.duration = event.duration;
    data.activity_status_id = event.activity.activity_status_id;
    data.remaining_effort = event.activity.remaining_effort;
    data.count_efforts_activity = event.count_efforts_activity;

    setSelectedActivity(data);
    setModalMode('activity');
  };

  const handleOnModalClose = () => {
    setSelectedActivity(null);
    setModalMode(null);
  };
  const handleOnUpdateList = () => {
    getEffortsByUser(currentRangeDate);
  };

  const handleOnDeleteActivity = () => {
    setModalMode('delete');
  };
  const handleOnDeleteConfirm = () => {
    deleteActivity(
      selectedActivity.activity_id,
      selectedActivity.effort_id,
      handleOnUpdateList,
      handleOnModalClose
    );
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setCloseDay(value);
    setModalMode('closeDay');
  };

  const handleOnMoveActivity = ({ event, start, end }) => {
    updateDrag(event.id, start, end);
  };

  const handleOnResizeActivity = ({ event, start, end }) => {
    updateDrag(event.id, start, end);
  };

  const handleOnViewChange = useCallback((view) => {
    setSelectedView(view);
  }, []);

  const handleOnNavigate = (date) => {
    setselectedDate(moment(date).format('YYYY-MM-DD'));
  };

  moment.locale('en');
  const localizer = momentLocalizer(moment);
  const DragAndDropCalendar = withDragAndDrop(Calendar);
  const customEventPropGetter = (event, start, end, isSelected) => {
    let backgroundColor = event.project_color;
    const style = {
      backgroundColor: backgroundColor,
      borderRadius: '0px',
      opacity: 0.8,
      color: 'white',
      border: '0px',
      display: 'block'
    };
    return { style: style };
  };

  const onRangeChange = useCallback((range) => {
    let startDate = moment(range[0]).format('YYYY-MM-DD');
    let endDate = moment(range[6]).format('YYYY-MM-DD');
    setCurrentRangeDate((prevState) => ({
      ...prevState,
      start: startDate,
      end: range.length === 1 ? startDate : endDate
    }));
  }, []);

  const handleOnCloseDayConfirm = () => {
    let params = {
      close_day: selectedDate,
      open_day: !closeDay
    };
    userCloseDay(params, handleOnUpdateList, handleOnModalClose);
  };

  return (
    <>
      <div className="activities">
        <PageTitle title="Actividades" />
      </div>

      <Paper className="activities_paper">
        <div className="activities_paper_closeday">
          <MUIDateField
            label="Actual Fecha de bloqueo"
            value={blockDay}
            name="next_block_day"
            disabled={true}
          />
          {!!CLOSE_DAY_USER_PERMISSION && selectedView === 'day' && (
            <FormControlLabel
              label="Día cerrado"
              control={
                <Switch
                  checked={closeDay}
                  onChange={(event) => onSwitchChange(event)}
                  name="active"
                  color="primary"
                  inputProps={{
                    'aria-label': 'secondary checkbox'
                  }}
                  disabled={!canEditCloseDay}
                />
              }
            />
          )}
        </div>
        <DragAndDropCalendar
          components={{
            event: EventWrapper
          }}
          eventPropGetter={customEventPropGetter}
          localizer={localizer}
          events={efforts}
          step={30}
          // view="day"
          views={['week', 'day']}
          min={new Date(2008, 0, 1, 0, 0)} // 8.00 AM
          max={new Date(2025, 0, 1, 23, 59)} // Max will be 6.00 PM!
          // defaultDate={new Date()}
          date={selectedDate}
          onNavigate={handleOnNavigate}
          defaultView={selectedView}
          onView={handleOnViewChange}
          showMultiDayTimes
          resizable
          selectable
          onSelectSlot={handleOnSelectTimeRange}
          onSelectEvent={handleOnSelectActivity}
          // onSelecting={(slot) => false}
          onRangeChange={onRangeChange}
          style={{ minHeight: 600 }}
          onEventDrop={handleOnMoveActivity}
          onEventResize={handleOnResizeActivity}
          popup={true}
        />
      </Paper>

      {selectedActivity && modalMode === 'activity' && (
        <ActivityModal
          projects={projects}
          activityData={selectedActivity}
          modalMode={modalMode}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
          onDelete={handleOnDeleteActivity}
        />
      )}

      {selectedActivity && modalMode === 'delete' && (
        <ActivityDeleteModal
          modalMode={modalMode}
          open={true}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Cliente"
          activityData={selectedActivity}
        />
      )}
      {selectedDate && modalMode === 'closeDay' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnCloseDayConfirm}
          title={closeDay === 1 ? 'Cerrar día' : 'Abrir día'}
        >
          {'¿Estás seguro que quieres '}
          {closeDay === 1 ? 'cerrar ' : 'abrir '}
          {' el día ' + selectedDate + '?'}
        </Modal>
      )}
    </>
  );
};

export default ActivitiesPage;
