/** Import helpers section **/
import { useCallback, useState } from 'react';

/** Import services section **/

import { getActivitiesSummaryService } from '../../../api/services/activities-services';
import { getProjectsSelectService } from '../../../api/services/workpackage-services';
import { colaboratorListSelectService } from '../../../api/services/users-services';

const usePageActions = () => {
  const [activitiesSummary, setActivitiesSummary] = useState([]);
  const [loading, setLoading] = useState(false);
  const [projects, setProjects] = useState([]);
  const [colaborators, setColaborators] = useState([]);

  const getActivitiesSummary = useCallback((params) => {
    setLoading(true);
    getActivitiesSummaryService(params)
      .then((response) => {
        setActivitiesSummary(response?.efforts);
      })
      .catch((error) => {
        console.error(
          'ActivitiesSummaryPage - Error requesting ActivitiesSummary list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getProjects = useCallback(() => {
    getProjectsSelectService()
      .then((response) => {
        if (response?.projects) setProjects(response?.projects);
      })
      .catch((error) => {
        console.error('UserAddModal - Error getting: ', error);
      });
  }, []);

  const getColaboratorsListSelect = useCallback(() => {
    colaboratorListSelectService()
      .then((response) => {
        if (response?.users) setColaborators(response?.users);
      })
      .catch((error) => {
        console.error('UserAddModal - Error getting: ', error);
      });
  }, []);

  return {
    activitiesSummary,
    projects,
    colaborators,
    loading,
    getActivitiesSummary,
    getProjects,
    getColaboratorsListSelect
  };
};

export default usePageActions;
