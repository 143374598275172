/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getProjectStatusService,
  getProjectTypesService,
  getProjectsService,
  deleteProjectsService
} from '../../../api/services/projects-services';

import { getClientsSelectService } from '../../../api/services/clients-services';

const usePageActions = () => {
  const [projectStatus, setProjectStatus] = useState([]);
  const [projectTypes, setProjectTypes] = useState([]);
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);

  const getProjects = useCallback((setProjects,setOriginalData) => {
    setLoading(true);
    getProjectsService()
      .then((response) => {
        setProjects(response?.projects);
        setOriginalData(response?.projects);
      })
      .catch((error) => {
        console.error('ProjectsPage - Error requesting Projects: ', error);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getProjectTypes = useCallback(() => {
    getProjectTypesService()
      .then((response) => {
        if (response?.project_types) setProjectTypes(response?.project_types);
      })
      .catch((error) => {
        console.error('ProjectsPage - Error getting ProjectType: ', error);
      });
    }, []);

 
  const getProjectStatus = useCallback (() => {
    getProjectStatusService()
      .then((response) => {
        if (response?.project_status)
          setProjectStatus(response?.project_status);
      })
      .catch((error) => {
        console.error('ProjectsPage - Error getting ProjectStatus: ', error);
      });
  }, []);

  const getClients = useCallback(() => {
    getClientsSelectService()
      .then((response) => {
        if (response?.clients) setClients(response?.clients);
      })
      .catch((error) => {
        console.error('ProjectsPage - Error getting Clients: ', error);
      });
  }, []);

  const deleteProject = (id, handleOnUpdateList) => {
    deleteProjectsService({ id })
    .then(() => {
      toast.success('Proyecto borrado');
      handleOnUpdateList();
    })
    .catch((error) => {
      toast.error('Error, no se puede borrar el proyecto.');
    });
  };

  return {
    loading,
    projectStatus,
    projectTypes,
    clients,
    getProjects,
    getProjectTypes,
    getProjectStatus,
    deleteProject,
    getClients
  };
};

export default usePageActions;
