/** Import helpers section **/
import { useState } from 'react';

/** Import services section **/
import { changePasswordRequestService } from '../../../api/services/change-password-services';

const usePageActions = () => {
  const [successRequest, setSuccessRequest] = useState(false);
  const [requested, setRequested] = useState(false);
  const [loading, setLoading] = useState(false);

  const changePassword = (email) => {
    setLoading(true);
    changePasswordRequestService({ email })
      .then((response) => {
        setSuccessRequest(true);
      })
      .catch((err) => {
        setSuccessRequest(false);
      })
      .finally(() => {
        setRequested(true);
        setLoading(false);
      });
  };

  const onResetRequest = () => {
    setRequested(false);
    setLoading(false);
  };

  return {
    changePassword,
    onResetRequest,
    loading,
    successRequest,
    requested
  };
};

export default usePageActions;
