/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';
import moment from 'moment';

/** Import helpers section **/
import { inputChange } from './../../../../utils/formUtils';

/** Import syles section **/
import './ActivityModalStyles.scss';

/** Import component section **/
import useModalActions from '../hooks/useModalActions';
import ModalComponent from '../../../../components/Modal';
import MUITextField from '../../../../components/inputs/MUITextField';
import MUISelect from '../../../../components/inputs/MUISelect';

const ActivityModalComponent = ({
  activityData,
  projects,
  onClose,
  onDelete,
  onUpdateList
}) => {
  const [activity, setActivity] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  const {
    activities,
    workpackages,
    activityStatus,
    getActivityStatus,
    createActivity,
    updateActivity,
    getWorkPackages,
    getActivities
  } = useModalActions();

  useEffect(() => {
    getActivityStatus();
  }, [getActivityStatus]);

  useEffect(() => {
    const newActivity = Object.assign(activityData);
    setActivity(newActivity);
  }, [activityData]);

  useEffect(() => {
    let workpackageId = activityData.workpackage_id;
    if (workpackageId) {
      getActivities(workpackageId);
    }
  }, [activityData.workpackage_id, getActivities]);

  useEffect(() => {
    const workpackageFound = workpackages.find(
      (wp) => wp.id === activity?.workpackage_id
    );
    if (workpackages.length > 0 && !workpackageFound) {
      handleOnInputChange({ name: 'workpackage_id', value: null });
    }
  }, [workpackages, activity?.workpackage_id]);

  useEffect(() => {
    const activityFound = activities.find(
      (wp) => wp.id === activity?.activity_id
    );
    if (activities.length > 0 && !activityFound) {
      handleOnInputChange({ name: 'activity_id', value: null });
    }
  }, [activities, activity?.activity_id]);

  const handleOnSave = () => {
    if (activity.id) {
      updateActivity(activity, onUpdateList, onClose);
    } else {
      createActivity(activity, onUpdateList, onClose);
    }
  };

  useEffect(() => {
    if (!activity) return;
    let validated = false;
    const title = activity?.title?.toString().trim();
    const activity_id = activity?.activity_id;
    const workpackage_id = activity?.workpackage_id;
    const activity_status_id = activity?.activity_status_id;
    if (
      (workpackage_id && title !== '' && activity_status_id) ||
      (workpackage_id && activity_id && activity_status_id)
    ) {
      validated = true;
    }

    setDataComplete(validated);
  }, [activity]);

  useEffect(() => {
    let projectId = activityData.project_id;
    if (projectId) {
      getWorkPackages(projectId);
    }
    // const newActivity = Object.assign(activityData);
    // setActivity(newActivity);
  }, [activityData.project_id, getWorkPackages]);

  const handleOnInputChange = (data) => {
    const { name, value } = data;
    setActivity((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleOnProjectChange = (data) => {
    const projectId = data.value;
    handleOnInputChange(data);

    if (projectId) {
      getWorkPackages(projectId);
    }
  };

  const handleOnWorkPackageChange = (data) => {
    const workpackageId = data.value;
    handleOnInputChange(data);
    getActivities(workpackageId);
  };

  let start = moment(activity?.start);
  let end = moment(activity?.end);
  let total = end.diff(start, 'hours', true);

  const title = activity?.id
    ? `Editar ${activity?.title} de ${start.format('h:mm a')} a ${end.format(
        'h:mm a'
      )}`
    : `Agregar ${activity?.title} de ${start.format('h:mm a')} a ${end.format(
        'h:mm a'
      )}`;

  return (
    <ModalComponent
      open={true}
      title={title}
      element={activity}
      onClose={onClose}
      onConfirm={handleOnSave}
      disabled={!dataComplete}
      onDelete={activity?.id ? onDelete : false}
    >
      <div className="activityModal">
        <span>De: {moment(activity?.start).format('h:mm a')}</span>
        <span>A: {moment(activity?.end).format('h:mm a')}</span>
        <span>
          Total:{' '}
          {activity?.duration?.toString()
            ? activity?.duration?.toString()
            : total}{' '}
          hrs
        </span>
        <MUISelect
          label="Proyecto"
          defaultValue={activity?.project_id}
          items={projects}
          idSelector="id"
          selector="name"
          noSelectedLabel="Select"
          name="project_id"
          onChange={handleOnProjectChange}
        />

        <MUISelect
          label="Paquete de trabajo"
          defaultValue={activity?.workpackage_id}
          items={workpackages}
          idSelector="id"
          selector="title"
          noSelectedLabel="Select"
          name="workpackage_id"
          onChange={handleOnWorkPackageChange}
          disabled={activity?.project_id ? false : true}
        />
        <MUISelect
          label="Actividades"
          defaultValue={activity?.activity_id}
          items={activities}
          idSelector="id"
          selector="title"
          noSelectedLabel="Select"
          name="activity_id"
          onChange={(data) => inputChange(setActivity, data)}
          disabled={activity?.workpackage_id ? false : true}
        />
        {!activity?.id && (
          <MUITextField
            label="Actividad nueva"
            defaultValue={activity?.title}
            type="text"
            name="title"
            id="title"
            onChange={(data) => inputChange(setActivity, data)}
          />
        )}

        <MUISelect
          label="Estatus"
          defaultValue={activity?.activity_status_id}
          items={activityStatus}
          idSelector="id"
          selector="title"
          noSelectedLabel="Select"
          name="activity_status_id"
          onChange={(data) => inputChange(setActivity, data)}
        />
        <MUITextField
          label="Tiempo restante"
          defaultValue={activity?.remaining_effort}
          type="text"
          name="remaining_effort"
          id="remaining_effort"
          onChange={(data) => inputChange(setActivity, data)}
          disabled={activity?.activity_status_id === 1 ? false : true}
        />
      </div>
    </ModalComponent>
  );
};

export default ActivityModalComponent;
