/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/

/** Import resources section **/
import { Tree } from '@minoru/react-dnd-treeview';

/** Import helpers section **/
import './TreeStyles.scss';

/** Import component section **/
import CustomNode from './Node';
import NodeDrag from './NodeDrag';

const TreeComponent = ({ workPackages, onAdd, onEdit, onDelete, onUpdate }) => {
  const handleOnDropNode = (newTree, { dragSourceId, dropTargetId }) => {
    onUpdate(dragSourceId, dropTargetId);
  };
  return (
    <Tree
      tree={workPackages}
      rootId={0}
      onDrop={handleOnDropNode}
      render={(node, { depth, isOpen, onToggle }) => (
        <CustomNode
          node={node}
          depth={depth}
          isOpen={isOpen}
          onToggle={onToggle}
          onAdd={onAdd}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      )}
      dragPreviewRender={(monitorProps) => (
        <NodeDrag monitorProps={monitorProps} />
      )}
      classes={{
        root: 'tree',
        draggingSource: 'tree_draggingSource',
        dropTarget: 'tree_dropTarget'
      }}
    />
  );
};

export default TreeComponent;
