/** Import react/libraries section **/
import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

/** Import resources section **/
import { APP_NAME } from '../../config/APP_CONFIGS';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import logo from '../../assets/logo_mediatasoft_blanco.png';

/** Import helpers section **/
import { clearSession } from '../../store/slices/session-slice';

/** Import component section **/
import Modal from '../../components/Modal';

/** Import styles section **/
import './HeaderStyles.scss';

export const HeaderPage = () => {
  const dispatch = useDispatch();
  let userName = useSelector((store) => store.session.profile?.name);
  const [modalMode, setModalMode] = useState(null);

  const handleOnLogout = () => {
    setModalMode('logout');
  };

  const handleOnLogoutConfirm = () => {
    dispatch(clearSession());
  };

  const handleOnModalClose = () => {
    setModalMode(null);
  };

  return (
    <>
      <div className="mainheader">
        <div className="mainheader_title">
          <img src={logo} alt="ms-logo" className="mainheader_logo" />
          {APP_NAME}
        </div>

        <div className="mainheader_session">
          <PersonIcon className="mainheader_session_usericon" />
          <div className="mainheader_session_profile">
            <div className="mainheader_session_profile_user">{userName}</div>
          </div>
          <div className="mainheader_session_logout">
            <ExitToAppIcon
              className="mainheader_session_logout_icon"
              onClick={handleOnLogout}
            />
          </div>
        </div>
      </div>

      {modalMode === 'logout' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnLogoutConfirm}
          title="Cerrar Sesión"
        >
          {'¿Estas seguro que quieres salir de la cuenta?'}
        </Modal>
      )}
    </>
  );
};

export default HeaderPage;
