import React, { forwardRef } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.normal,
    padding: '5',
    margin: 0
  },
  checkbox: {
    marginRight: '5px',
    transform: 'scale(1.3)'
  }
}));

export const MUICheckBox = forwardRef(
  ({ label, checked, onChange, ...otherProps }, ref) => {
    const classes = useStyles();

    const handleOnChange = (event) => {
      onChange(event.target.checked);
    };

    return (
      <FormControlLabel
        className={classes.root}
        control={
          <Checkbox
            ref={ref}
            color="default"
            checked={checked}
            onChange={handleOnChange}
            className={classes.checkbox}
            {...otherProps}
          />
        }
        label={label}
      />
    );
  }
);

export default MUICheckBox;
