import request from '../api-connector';

export const getProjectsSelectService = () => {
  const serviceDef = {
    url: 'project/list/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getWorkPackagesByProjectService = ({ project_id }) => {
  const serviceDef = {
    url: 'workpackage/list',
    method: 'get',
    params: { project_id }
  };
  return request(serviceDef);
};

export const getWorkPackageStatusService = () => {
  const serviceDef = {
    url: 'workpackage/list/status',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addWorkPackagesService = ({
  title,
  use_case_code,
  planned_effort,
  workpackage_status_id,
  project_id,
  parent_workpackage_id
}) => {
  const serviceDef = {
    url: 'workpackage/create',
    method: 'post',
    params: {
      title,
      use_case_code,
      planned_effort,
      workpackage_status_id,
      project_id,
      parent_workpackage_id
    }
  };
  return request(serviceDef);
};

export const getWorkPackageByIdService = ({ id }) => {
  const serviceDef = {
    url: 'workpackage/read',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};

export const updateWorkPackagesService = ({
  id,
  title,
  use_case_code,
  planned_effort,
  workpackage_status_id,
  project_id,
  parent_workpackage_id
}) => {
  const serviceDef = {
    url: 'workpackage/update',
    method: 'post',
    params: {
      id,
      title,
      use_case_code,
      planned_effort,
      workpackage_status_id,
      project_id,
      parent_workpackage_id
    }
  };
  return request(serviceDef);
};

export const updateWorkPackagetreeService = ({
  dragSourceId,
  dropTargetId
}) => {
  const serviceDef = {
    url: 'workpackage/update/tree',
    method: 'post',
    params: { dragSourceId, dropTargetId }
  };
  return request(serviceDef);
};

export const deleteWorkPackageService = ({ id }) => {
  const serviceDef = {
    url: 'workpackage/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const getClientsLightService = () => {
  const serviceDef = {
    url: 'client/list/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};
