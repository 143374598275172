/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import {
  addPermissionService,
  updatePermissionService
} from '../../../../../api/services/permission-services';

const useModalActions = () => {
  const createPermission = (permission, callback) => {
    addPermissionService(permission)
      .then((response) => {
        toast.success('Permission created');
        callback();
      })
      .catch((error) => {
        toast.error('Permission create error');
      });
  };

  const updatePermission = (permission, callback) => {
    updatePermissionService(permission)
      .then(() => {
        toast.success('Permission updated');
        callback();
      })
      .catch((error) => {
        toast.error('Permission update error');
      });
  };

  return {
    createPermission,
    updatePermission
  };
};

export default useModalActions;
