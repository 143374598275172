/** Import react/libraries section **/
import React from 'react';

/** Import helpers section **/
import { makeStyles } from '@material-ui/core/styles';

/** Import resources section **/
import LinearProgress from '@material-ui/core/LinearProgress';

/** Import component section **/

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%'
    // marginTop: '10px'
  }
}));

export const LoadingComponent = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <LinearProgress color="secondary" />
    </div>
  );
};

export default LoadingComponent;
