/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  addWorkPackagesService,
  updateWorkPackagesService,
  getWorkPackageStatusService
} from '../../../../api/services/workpackage-services';

const useModalActions = () => {
  const [workPackageStatus, setWorkPackageStatus] = useState([]);

  const getWorkPackageStatus = useCallback(() => {
    getWorkPackageStatusService()
      .then((response) => {
        if (response?.workpackageStatus)
          setWorkPackageStatus(response?.workpackageStatus);
      })
      .catch((error) => {
        console.error('WorkPackagesAddModal - Error getting: ', error);
      });
  }, []);

  const createWorkPackage = (workPackage, onUpdateList, onClose) => {
    addWorkPackagesService(workPackage)
      .then((response) => {
        toast.success('Work Package created');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Work Package create error');
      });
  };

  const updateWorkPackage = (workPackage, onUpdateList, onClose) => {
    updateWorkPackagesService(workPackage)
      .then(() => {
        toast.success('Work Package updated');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Work Package update error');
      });
  };

  return {
    workPackageStatus,
    getWorkPackageStatus,
    createWorkPackage,
    updateWorkPackage
  };
};

export default useModalActions;
