/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import moment from 'moment';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker } from '@material-ui/pickers';

import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      paddingTop: '10px'
    },
    '& .MuiInputBase-adornedStart': {
      paddingLeft: '10px',
      paddingTop: '5px',
      width: '100%'
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1.5px'
    },
    '& .MuiIconButton-root': {
      padding: '0'
    },
    '& .MuiInputLabel-root': {
      // whiteSpace: 'nowrap',
      height: '12px',
      fontSize: '16px',
      backgroundColor: 'white',
      padding: '10px 5px 10px 0', // padding right para separación con línea
      color: theme.palette.normal.main
    }
  },
  datePicker: {},
  input: {
    padding: '5px 10px 5px 0',
    fontSize: '12px',
    height: '30px'
  }
}));

export const MUIDateField = ({
  id,
  name,
  label,
  value,
  onChange,
  minDate = undefined,
  disabled,
  ...props
}) => {
  const classes = useStyles();
  const [date, setDate] = useState(null);

  const handleOnDateChange = (e) => {
    if (e && moment.isMoment(e)) {
      let newValue = e.format('yyy-MM-DD');
      onChange({ value: newValue, id: id, name: name });
    }
  };

  useEffect(() => {
    if (value === null || value === undefined) {
      setDate(null);
      return;
    }
    const newValue = moment.isMoment(value)
      ? value
      : moment(value, ['DD/MM/yyyy', 'yyyy-MM-DD']);

    if (!newValue.isSame(date)) {
      setDate(newValue);
    }
  }, [value]);

  return (
    <div id={'MUI_' + id} className={classes.root}>
      <KeyboardDatePicker
        className={classes.datePicker}
        autoOk
        id={id}
        label={label}
        value={date}
        onChange={handleOnDateChange}
        placeholder="dd/mm/yy"
        format="DD/MM/yy"
        mask="__/__/__"
        InputAdornmentProps={{ position: 'start' }}
        disableToolbar
        inputVariant="outlined"
        variant="inline"
        disabled={disabled}
        minDate={disabled === true ? null : minDate}
        InputProps={{
          classes: {
            input: classes.input
          }
        }}
        // invalidDateMessage="Not valid date"
      />
    </div>
  );
};

MUIDateField.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired
};

export default MUIDateField;
