/** Import helpers section **/
// import { useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  addUserService,
  updateUserService,
  changePasswordService
} from '../../../../../api/services/users-services';

const useModalActions = ({ onClose, onUpdateList }) => {
  const addUser = (user, onUpdateList, onClose) => {
    addUserService(user)
      .then(() => {
        toast.success('User added correctly');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('User create error');
      });
  };

  const updateUser = (user, onUpdateList, onClose) => {
    updateUserService(user)
      .then(() => {
        toast.success('User updated correctly');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('User update error');
      });
  };

  const changePassword = (user, onUpdateList, onClose) => {
    changePasswordService(user)
      .then(() => {
        toast.success('User updated correctly');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.warning('User update error');
      });
  };

  return {
    addUser,
    updateUser,
    changePassword
  };
};

export default useModalActions;
