/** Import react/libraries section **/
import React from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Import helpers section **/
import { PUBLIC_ROUTES } from '../../config/ROUTES_CONTENT';

/** Import styles section **/
import './MainStyles.scss';

/** Import resources section **/

/** Import component section **/
import PrivateLayout from '../PrivateLayout';

const MainComponent = () => {
  let authenticated = useSelector((store) => store.session.authenticated);
  let role = useSelector((store) => store.session.role);

  return (
    <BrowserRouter>
      {authenticated && role ? (
        <PrivateLayout />
      ) : (
        <Switch>
          {PUBLIC_ROUTES.map((route, index) => (
            <Route
              exact
              key={index}
              path={route.path}
              component={route.component}
            />
          ))}
          <Redirect to="/" />
        </Switch>
      )}
    </BrowserRouter>
  );
};

export default MainComponent;
