/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import useModalActions from '../hooks/useModalActions';

/** Import styles section **/
import './styles.scss';

/** Import component section **/
import ModalComponent from '../../../../components/Modal';
import MUISelect from '../../../../components/inputs/MUISelect';

const ClientModalComponent = ({
  clientId,
  clientUserData,
  onClose,
  onUpdateList
}) => {
  const [clientUser, setClientUser] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isValid, setIsValid] = useState(false);

  const { addClientUser, getUserClients, users } = useModalActions({
    onClose,
    onUpdateList
  });

  useEffect(() => {
    const newClient = Object.assign(clientUserData);
    setClientUser(newClient);
    getUserClients();
  }, [clientUserData, getUserClients]);

  useEffect(() => {
    if (selectedUser) {
      setIsValid(true);
    }
  }, [selectedUser]);

  const handleOnSave = () => {
    if (selectedUser) {
      let params = {
        client_id: clientId,
        user_id: selectedUser
      };

      addClientUser(params, onClose, onUpdateList);
    }
  };

  const handleOnUserChange = (data) => {
    const userId = data.value;
    setSelectedUser(userId);
  };

  const title = 'Agregar usuario';

  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={handleOnSave}
      disabled={!isValid}
    >
      <div className="clientsModal">
        <MUISelect
          label="Usuario"
          defaultValue={clientUser?.user_id}
          items={users}
          idSelector="id"
          selector="name"
          noSelectedLabel="Select"
          name="user_id"
          onChange={handleOnUserChange}
        />
      </div>
    </ModalComponent>
  );
};

export default ClientModalComponent;
