/** Import react/libraries section **/
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loaded: false,
  routes: [],
  menus: [],
  actions: []
};

const permissionsSlice = createSlice({
  name: 'permissions',
  initialState: initialState,
  reducers: {
    setPermissions: (state, { payload: { routes, menus, actions } }) => {
      state.routes = routes;
      state.menus = menus;
      state.actions = actions;
      state.loaded = true;
    },
    clearPermissions: (state) => {
      state.routes = [];
      state.menus = [];
      state.actions = [];
      state.loaded = false;
    }
  }
});
export default permissionsSlice.reducer;

export const { setPermissions, clearPermissions } = permissionsSlice.actions;
