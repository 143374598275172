/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';

/** Import helpers section **/
import { LOGIN_ROUTE } from '../../../config/URL_ROUTES';

/** Import services section **/
import { registrationService } from '../../../api/services/registration-services';

const usePageActions = () => {
  const [registered, setRegistrationed] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  const registerUser = useCallback((userData) => {
    if (!userData) {
      return;
    }
    setLoading(true);
    registrationService(userData)
      .then((response) => {
        setRegistrationed(true);
      })
      .catch((err) => {
        if (err?.response_status === 406) {
          toast.warning(
            'Usuario no registrado previamente, contacte al administrador'
          );
        }
        if (err?.response_status === 401) {
          toast.warning(
            'Usuario registrado y activo; no puede registrarse nuevamente.'
          );
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const handleOnCancel = () => {
    setRegistrationed(false);
    history.push(LOGIN_ROUTE);
  };
  const handleOnConfirmModalClose = () => {
    setRegistrationed(false);
    history.push(LOGIN_ROUTE);
  };

  return {
    registered,
    loading,
    registerUser,
    handleOnCancel,
    handleOnConfirmModalClose
  };
};

export default usePageActions;
