import request from '../api-connector';

export const authService = () => {
  const serviceDef = {
    url: 'auth',
    method: 'post',
    params: null
  };
  return request(serviceDef);
};

export const loginService = ({ username, password }) => {
  const serviceDef = {
    url: 'login',
    method: 'post',
    params: { email: username, password }
  };
  return request(serviceDef);
};
