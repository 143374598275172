/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { inputChange } from '../../../../utils/formUtils';

/** Import syles section **/
import './WorkPackageModalStyles.scss';

/** Import component section **/
import useModalActions from '../hooks/useModalActions';
import ModalComponent from '../../../../components/Modal';
import MUITextField from '../../../../components/inputs/MUITextField';
import MUISelect from '../../../../components/inputs/MUISelect';

const WorkPackagesModal = ({
  workPackageData,
  selectedProject,
  onClose,
  onUpdateList
}) => {
  const [workPackage, setWorkPackage] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  const {
    workPackageStatus,
    getWorkPackageStatus,
    createWorkPackage,
    updateWorkPackage
  } = useModalActions();

  useEffect(() => {
    getWorkPackageStatus();
  }, [getWorkPackageStatus]);

  useEffect(() => {
    const newWorkPackage = Object.assign({}, workPackageData);
    newWorkPackage.project_id = selectedProject;
    setWorkPackage(newWorkPackage);
  }, [workPackageData, selectedProject]);

  const handleOnSave = () => {
    if (workPackage.id) {
      updateWorkPackage(workPackage, onUpdateList, onClose);
    } else {
      createWorkPackage(workPackage, onUpdateList, onClose);
    }
  };

  useEffect(() => {
    if (!workPackage) return;
    let validated = true;

    const title = workPackage?.title?.toString().trim();
    const use_case_code = workPackage?.use_case_code?.toString().trim();
    const planned_effort = workPackage?.planned_effort?.toString().trim();
    const workPackage_status_id = workPackage?.workpackage_status_id;
    if (
      title === '' ||
      use_case_code === '' ||
      planned_effort === '' ||
      !workPackage_status_id
    ) {
      validated = false;
    }

    setDataComplete(validated);
  }, [workPackage]);

  const title = workPackage?.id
    ? 'Editar paquete de trabajo'
    : 'Agregar paquete de trabajo';

  return (
    <ModalComponent
      open={true}
      title={title}
      element={workPackage}
      onClose={onClose}
      onConfirm={handleOnSave}
      disabled={!dataComplete}
    >
      <div className="workPagckagesModal">
        <MUITextField
          label="Código"
          defaultValue={workPackage?.use_case_code}
          type="text"
          name="use_case_code"
          onChange={(data) => inputChange(setWorkPackage, data)}
        />
        <MUITextField
          label="Nombre"
          defaultValue={workPackage?.title}
          type="text"
          name="title"
          onChange={(data) => inputChange(setWorkPackage, data)}
        />
        <MUITextField
          label="Esfuerzo planeado"
          defaultValue={workPackage?.planned_effort}
          type="number"
          name="planned_effort"
          onChange={(data) => inputChange(setWorkPackage, data)}
        />
        <MUISelect
          label="Status"
          defaultValue={workPackage?.workpackage_status_id}
          items={workPackageStatus}
          idSelector="id"
          selector="title"
          noSelectedLabel="Select"
          name="workpackage_status_id"
          onChange={(data) => inputChange(setWorkPackage, data)}
        />
      </div>
    </ModalComponent>
  );
};

export default WorkPackagesModal;
