/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import {
  addProjectsService,
  updateProjectsService
} from '../../../../api/services/projects-services';

const useModalActions = () => {
  const createProject = (project, onUpdateList) => {
    addProjectsService(project)
      .then((response) => {
        toast.success('Project created');
        onUpdateList();
      })
      .catch((error) => {
        toast.error('Project create error');
      });
  };

  const updateProject = (project, onUpdateList) => {
    updateProjectsService(project)
      .then(() => {
        toast.success('Project updated');
        onUpdateList();
      })
      .catch((error) => {
        toast.error('Project update error');
      });
  };

  return {
    createProject,
    updateProject
  };
};

export default useModalActions;
