import request from '../api-connector';

export const getProjectsService = () => {
  const serviceDef = {
    url: 'project/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getProjectStatusService = () => {
  const serviceDef = {
    url: 'project/list/status',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getProjectTypesService = () => {
  const serviceDef = {
    url: 'project/list/type',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const addProjectsService = ({
  name,
  code,
  description,
  project_color,
  planned_effort,
  planned_start_date,
  planned_end_date,
  client_id,
  project_type_id,
  project_status_id
}) => {
  const serviceDef = {
    url: 'project/create',
    method: 'post',
    params: {
      name,
      code,
      description,
      project_color,
      planned_effort,
      planned_start_date,
      planned_end_date,
      client_id,
      project_type_id,
      project_status_id
    }
  };
  return request(serviceDef);
};

export const getProjectsByIdService = ({ id }) => {
  const serviceDef = {
    url: 'project/read',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};

export const updateProjectsService = ({
  id,
  name,
  code,
  description,
  project_color,
  planned_effort,
  planned_start_date,
  planned_end_date,
  client_id,
  project_type_id,
  project_status_id
}) => {
  const serviceDef = {
    url: 'project/update',
    method: 'post',
    params: {
      id,
      name,
      code,
      description,
      project_color,
      planned_effort,
      planned_start_date,
      planned_end_date,
      client_id,
      project_type_id,
      project_status_id
    }
  };
  return request(serviceDef);
};

export const deleteProjectsService = ({ id }) => {
  const serviceDef = {
    url: 'project/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};
