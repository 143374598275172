import React, { useState, useEffect } from 'react';

/** Import helpers section **/
import { CHECK_IN_LOCATION_MODE } from '../../constants';

/** Import component section **/
import ModalComponent from '../../../../components/Modal';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';

let defaultProps = {
  center: {
    lat: 0,
    lng: 0
  },
  zoom: 11
};

const checkInIcon = () => {
  return (
    <LocationOnIcon className="g-row-action-button check"></LocationOnIcon>
  );
};

const CheckInLocationModal = ({ checkInData, onClose, mode }) => {
  const checkLocationIcon =
    mode === CHECK_IN_LOCATION_MODE ? (
      <LocationOnIcon className="g-row-action-button check"></LocationOnIcon>
    ) : (
      <LocationOnIcon className="g-row-action-button checkout"></LocationOnIcon>
    );

  const AnyReactComponent = ({ text }) => (
    <div>
      {checkLocationIcon}
      {text}
    </div>
  );

  useEffect(() => {
    if (mode === CHECK_IN_LOCATION_MODE) {
      defaultProps.center.lat = checkInData?.check_in_latitude;
      defaultProps.center.lng = checkInData?.check_in_longitude;
    } else {
      defaultProps.center.lat = checkInData?.check_out_latitude;
      defaultProps.center.lng = checkInData?.check_out_longitude;
    }
  }, [mode]);

  const title =
    mode === CHECK_IN_LOCATION_MODE
      ? 'Ubicacion del check in'
      : 'Ubicacion del check out';
  return (
    <ModalComponent
      open={true}
      title={title}
      element={checkInData}
      onClose={onClose}
    >
      <div style={{ height: '50vh', width: '100%' }}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: '' }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          <AnyReactComponent
            lat={defaultProps.center.lat}
            lng={defaultProps.center.lng}
            text="Location"
          />
        </GoogleMapReact>
      </div>
    </ModalComponent>
  );
};

export default CheckInLocationModal;
