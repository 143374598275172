import request from '../api-connector';

export const getLiberateActivitiesService = () => {
  const serviceDef = {
    url: 'liberate-activities/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const blockDayService = ({ block_day }) => {
  const serviceDef = {
    url: 'liberate-activities/block-day',
    method: 'get',
    params: { block_day }
  };
  return request(serviceDef);
};
