/** Import react/libraries section **/
import React from 'react';
import PropTypes from 'prop-types';

/** Import styles section **/
import './ConfirmModalStyles.scss';

/** Import resources section **/
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Paper from '@material-ui/core/Paper';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';

/** Import component section **/

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: '20px'
  }
}));

const ConfirmModalComponent = (props) => {
  const { title, message, onClose } = props;
  const classes = useStyles();

  return (
    <Dialog
      open={true}
      onClick={onClose}
      onClose={onClose}
      className="confirmModal"
      classes={{
        paper: classes.paper
      }}
    >
      <Paper className="confirmModal_container">
        <div className="confirmModal_container_title">{title}</div>
        <CheckCircleOutlineIcon className="confirmModal_container_icon" />
        <div className="confirmModal_container_message">{message}</div>
      </Paper>
    </Dialog>
  );
};

ConfirmModalComponent.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default ConfirmModalComponent;
