/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';

/** Import helpers section **/

/** Import helpers section **/
import './ProjectsStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

/** Import component section **/
import ProjectModal from './components/ProjectModal';
import Modal from '../../components/Modal';
import usePageActions from './hooks/usePageActions';
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import FilterBy from '../../components/FilterBy';

const projectsModel = {
  id: null,
  name: '',
  description: '',
  project_color: '',
  planned_effort: '',
  planned_start_date: null,
  planned_end_date: null,
  client_id: '',
  project_type_id: '',
  project_status_id: ''
};

const ProjectsPage = () => {
  const [projects, setProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [originalData, setOriginalData] = useState([]);

  const {
    clients,
    projectStatus,
    projectTypes,
    loading,
    getProjectStatus,
    getProjectTypes,
    getClients,
    getProjects,
    deleteProject
  } = usePageActions();

  useEffect(() => {
    getClients();
    getProjectStatus();
    getProjectTypes();
    getProjects(setProjects, setOriginalData);
  }, [
    getClients,
    getProjectStatus,
    getProjectTypes,
    getProjects,
    setOriginalData
  ]);

  const handleOnUpdateList = () => {
    getProjects(setProjects, setOriginalData);
  };

  const handleOnAddProject = () => {
    setSelectedProject(Object.assign({}, projectsModel));
    setModalMode('project');
  };

  const handelOnDeleteProject = (row) => {
    setSelectedProject(row);
    setModalMode('delete');
  };

  const handleOnModalClose = () => {
    setSelectedProject(null);
    setModalMode(null);
  };

  const handleOnDeleteConfirm = () => {
    deleteProject(selectedProject.id, handleOnUpdateList);
    setSelectedProject(null);
    setModalMode(null);
  };

  const handleOnEditProject = (row) => {
    setSelectedProject(row);
    setModalMode('project');
  };

  const handleOnFilterProjects = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.name?.toString().toLowerCase();
        const field2 = item.code?.toString().toLowerCase();
        const field4 = item.planned_effort?.toString();
        return (
          field1.match(filteringText) ||
          field2.match(filteringText) ||
          (field4.match(filteringText) && true)
        );
      });
    }

    setProjects(filteredItems);
  };

  const ColorCell = ({ row }) => {
    const color = row?.project_color ? row.project_color : '#ffff';
    return (
      <div className="projects_color-cell">
        <div
          style={{ backgroundColor: color }}
          className="projects_color-cell_color"
        />
      </div>
    );
  };
  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          className="g-row-action-button"
          onClick={() => handleOnEditProject(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => handelOnDeleteProject(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Codigo',
        selector: 'code',
        sortable: true,
        center: true
      },
      {
        name: 'Nombre',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'Cliente',
        selector: (row) => row?.client?.name,
        sortable: true,
        center: true
      },

      {
        name: 'Descripción',
        selector: 'description',
        sortable: true,
        center: true
      },
      {
        name: 'Esfuerzo planeado',
        selector: 'planned_effort',
        sortable: true,
        center: true
      },
      {
        name: 'Fecha inicio planeada',
        selector: 'planned_start_date',
        sortable: true,
        center: true
      },
      {
        name: 'Fecha fin planeada',
        selector: 'planned_end_date',
        sortable: true,
        center: true
      },

      {
        name: 'Tipo de proyecto',
        selector: (row) => row?.project_type?.type,
        sortable: true,
        center: true
      },
      {
        name: 'Estatus del proyecto',
        selector: (row) => row?.project_status?.title,
        sortable: true,
        center: true
      },
      {
        name: 'Color',
        cell: (row) => <ColorCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <div className="projects">
        <PageTitle title="Proyectos" />
        <Paper className="g-page-header projects_header">
          <div className="projects_header_filterby">
            <FilterBy onChange={handleOnFilterProjects} />
          </div>
          <Button
            className="projects_header_button"
            onClick={handleOnAddProject}
            startIcon={<AddCircleIcon />}
          >
            Crear nuevo proyecto
          </Button>
        </Paper>
        <DataTable data={projects} columns={tableColumns} loading={loading} />
      </div>
      {selectedProject && modalMode === 'project' && (
        <ProjectModal
          projectData={selectedProject}
          clients={clients}
          projectStatus={projectStatus}
          projectTypes={projectTypes}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
      {selectedProject && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Projecte"
        >
          {'¿Estas seguro que quieres borrar el proyecto ' +
            selectedProject.name +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default ProjectsPage;
