import request from '../api-connector';

export const userCheckOutService = ({
  check_id,
  user_id,
  date,
  departure_time,
  check_out_comments,
  latitude,
  longitude
}) => {
  const serviceDef = {
    url: 'user/check-out',
    method: 'post',
    params: {
      check_id,
      user_id,
      date,
      departure_time,
      check_out_comments,
      latitude,
      longitude
    }
  };
  return request(serviceDef);
};

export const userCheckInService = ({
  check_id,
  user_id,
  date,
  entry_time,
  check_in_comments,
  latitude,
  longitude
}) => {
  const serviceDef = {
    url: 'user/check-in',
    method: 'post',
    params: {
      check_id,
      user_id,
      date,
      entry_time,
      check_in_comments,
      latitude,
      longitude
    }
  };
  return request(serviceDef);
};

export const userCheckInListService = ({ start_date, end_date }) => {
  const serviceDef = {
    url: 'user/list/check-in',
    method: 'get',
    params: { start_date, end_date }
  };
  return request(serviceDef);
};

export const userCheckInDeleteService = ({ check_id }) => {
  const serviceDef = {
    url: 'user/delete/check-in',
    method: 'post',
    params: { check_id }
  };
  return request(serviceDef);
};

export const userCheckInEditService = ({
  check_id,
  user_id,
  date,
  entry_time,
  departure_time,
  check_out_comments,
  check_in_comments,
  latitude,
  longitude
}) => {
  const serviceDef = {
    url: 'user/update/check-in',
    method: 'post',
    params: {
      check_id,
      user_id,
      date,
      entry_time,
      departure_time,
      check_out_comments,
      check_in_comments,
      latitude,
      longitude
    }
  };
  return request(serviceDef);
};
