/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getPermissionsService,
  deletePermissionService
} from '../../../api/services/permission-services';

const useActions = () => {
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPermissions = useCallback(() => {
    setLoading(true);
    getPermissionsService()
      .then((response) => {
        setPermissions(response?.permissions);
      })
      .catch((error) => {
        console.error(
          'permissionsComponent - Error requesting permissions list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const deletePermission = (id) => {
    deletePermissionService({ id })
      .then(() => {
        getPermissions();
        toast.success('Permiso borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el permiso.');
      });
  };

  return {
    permissions,
    loading,
    getPermissions,
    deletePermission
  };
};

export default useActions;
