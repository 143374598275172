/** Import react/libraries section **/
import { createSlice } from '@reduxjs/toolkit';

/** Import helpers section **/
import asynkstorage from 'asynkstorage';
import { LOCAL_STORAGE_PREFIX } from '../../config/APP_CONFIGS';

const localStoragePrefix = LOCAL_STORAGE_PREFIX
  ? LOCAL_STORAGE_PREFIX + '@'
  : '';

const initialState = {
  authenticated: false,
  token: null,
  role: null,
  profile: {
    name: ''
  }
};

const sessionSlice = createSlice({
  name: 'session',
  initialState: initialState,
  reducers: {
    setSession: (state, { payload: { token, role, profile } }) => {
      state.authenticated = true;
      state.token = token;
      state.role = role;
      state.profile = profile;
      storeToken({ token });
      storeRole({ role });
      storeProfile(profile);
    },
    clearSession: (state) => {
      state.token = initialState.token;
      state.role = initialState.role;
      state.profile = initialState.profile;
      state.loggedIn = initialState.loggedIn;
      clearLocalStorage();
    }
  }
});
export default sessionSlice.reducer;

export const { setSession, clearSession } = sessionSlice.actions;

// LOCAL STORAGE MANAGE FUNCTIONS

export const getTokenStored = new Promise((resolve, reject) => {
  asynkstorage
    .getItem(localStoragePrefix + 'token')
    .then((token) => {
      return resolve(token);
    })
    .catch((e) => {
      return resolve(null);
    });
});

export const getSessionStored = new Promise((resolve, reject) => {
  let localStore = {
    [localStoragePrefix + 'token']: null,
    [localStoragePrefix + 'role']: null,
    [localStoragePrefix + 'profile']: null
  };

  asynkstorage
    .getItem(localStoragePrefix + 'token')
    .then((token) => {
      localStore.token = token;
      asynkstorage
        .getItem(localStoragePrefix + 'role')
        .then((role) => {
          if (role) localStore.role = role;
          asynkstorage
            .getItem(localStoragePrefix + 'profile')
            .then((profile) => {
              if (profile) localStore.profile = JSON.parse(profile);
              return resolve(localStore);
            })
            .catch((e) => {
              // catch profile
              return resolve(localStore);
            });
        })
        .catch((e) => {
          // catch role
          return resolve(localStore);
        });
    })
    .catch((e) => {
      // catch token
      return resolve(localStore);
    });
});

export async function storeToken({ token }) {
  const response = new Promise((resolve, reject) => {
    asynkstorage
      .setItem(localStoragePrefix + 'token', token)
      .then(() => {
        return resolve(true);
      })
      .catch((e) => {
        console.error('storeToken error: ', e.message);
        return reject(false);
      });
  });

  return response;
}

export async function storeRole({ role }) {
  const response = new Promise((resolve, reject) => {
    asynkstorage
      .setItem(localStoragePrefix + 'role', role)
      .then(() => {
        return resolve(true);
      })
      .catch((e) => {
        console.error('storeRole error: ', e.message);
        return reject(false);
      });
  });
  return response;
}

export async function storeProfile(profile) {
  const response = new Promise((resolve, reject) => {
    asynkstorage
      .setItem(localStoragePrefix + 'profile', JSON.stringify(profile))
      .then(() => {
        return resolve(true);
      })
      .catch((e) => {
        console.error('storeProfile error: ', e.message);
        return reject(false);
      });
  });
  return response;
}

export async function clearLocalStorage() {
  const response = new Promise((resolve, reject) => {
    asynkstorage
      .removeItem(localStoragePrefix + 'token')
      .then((token) => {
        asynkstorage
          .removeItem(localStoragePrefix + 'role')
          .then((role) => {
            asynkstorage
              .removeItem(localStoragePrefix + 'profile')
              .then((profile) => {
                return resolve(true);
              })
              .catch((e) => {
                // catch profile
                return resolve(false);
              });
          })
          .catch((e) => {
            // catch role
            return resolve(false);
          });
      })
      .catch((e) => {
        // catch token
        return resolve(false);
      });
  });
  return response;
}
