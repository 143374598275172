/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { inputChange } from '../../../../utils/formUtils';
import fieldsRules from './helpers/rules';
import useModalForm from '../../../../utils/useModalForm';
import useModalActions from './hooks/useModalActions';

/** Import styles section **/
import './styles.scss';

/** Import component section **/
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ModalComponent from '../../../../components/Modal';
import MUITextField from '../../../../components/inputs/MUITextField';
import MUISelect from '../../../../components/inputs/MUISelect';

const UserModal = ({ userData, modalMode, roles, onClose, onUpdateList }) => {
  const [user, setUser] = useState(null);

  const { handleOnChange, handleOnSubmit, isValid } = useModalForm(
    user,
    fieldsRules
  );

  const { addUser, updateUser, changePassword } = useModalActions({
    onClose,
    onUpdateList
  });

  useEffect(() => {
    const newUSer = Object.assign(userData);
    setUser(newUSer);
  }, [userData]);

  const onChangeInput = (data) => {
    const { name, value } = data;
    inputChange(setUser, data);
    handleOnChange(name, value);
  };

  const onSwitchChange = (event) => {
    let value = event.target.checked === false ? 0 : 1;
    setUser((prevState) => ({
      ...prevState,
      active: value
    }));
  };

  const handleOnSave = () => {
    if (modalMode === 'password') {
      changePassword(user, onUpdateList, onClose);
    } else if (user.id) {
      updateUser(user, onUpdateList, onClose);
    } else {
      addUser(user, onUpdateList, onClose);
    }
  };

  const title =
    modalMode === 'password'
      ? 'Cambiar contraseña'
      : user?.id
      ? 'Editar usuario'
      : 'Agregar usuario';

  let disabled =
    modalMode === 'password'
      ? !(!!user?.password && !!user?.password_conf)
      : !isValid;

  return (
    <ModalComponent
      open={true}
      title={title}
      element={user}
      onClose={onClose}
      onConfirm={handleOnSubmit(handleOnSave)}
      disabled={disabled}
    >
      <div className="usermodal">
        {modalMode === 'user' && (
          <>
            <FormControlLabel
              className="usermodal_active"
              control={
                <Switch
                  label="Activo"
                  checked={user?.active === 1}
                  onChange={(event) => onSwitchChange(event)}
                  name="active"
                  color="primary"
                  inputProps={{
                    'aria-label': 'secondary checkbox'
                  }}
                />
              }
              label="Activo"
            />
            <div className="usermodal_row">
              <MUITextField
                label="Nombre"
                defaultValue={user?.first_name}
                type="text"
                name="first_name"
                onChange={(data) => onChangeInput(data)}
              />
              <MUITextField
                label="Apellido"
                defaultValue={user?.last_name}
                type="text"
                name="last_name"
                onChange={(data) => onChangeInput(data)}
              />
            </div>
            <div className="usermodal_row">
              <MUITextField
                label="Email"
                defaultValue={user?.email}
                type="text"
                name="email"
                onChange={(data) => onChangeInput(data)}
              />

              <MUISelect
                label="Rol"
                defaultValue={user?.role_id}
                items={roles}
                idSelector="id"
                selector="title"
                noSelectedLabel="Select"
                name="role_id"
                onChange={(data) => onChangeInput(data)}
                className="usermodal_rolTitle"
              />
            </div>
          </>
        )}

        {modalMode === 'password' && !!user?.id && (
          <div className="usermodal_row">
            <MUITextField
              label="Contraseña"
              defaultValue={user?.password}
              type="password"
              name="password"
              error={user?.password !== user?.password_conf}
              onChange={(data) => onChangeInput(data)}
            />

            <MUITextField
              label="Confirmar contraseña"
              defaultValue={user?.password_conf}
              type="password"
              name="password_conf"
              error={user?.password !== user?.password_conf}
              onChange={(data) => onChangeInput(data)}
            />
          </div>
        )}
      </div>
    </ModalComponent>
  );
};

export default UserModal;
