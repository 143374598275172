/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { inputChange } from '../../../../utils/formUtils';

/** Import syles section **/
import './EffortsModalStyles.scss';

/** Import component section **/
import useModalActions from '../hooks/useModalActions';
import ModalComponent from '../../../../components/Modal';
import MUITextField from '../../../../components/inputs/MUITextField';
import MUIDateField from '../../../../components/inputs/MUIDateField';

const EffortsModal = ({ effortData, onClose, onUpdateList }) => {
  const [effort, setEffort] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  const { updateEffort, createEffort } = useModalActions();

  useEffect(() => {
    const newEffort = Object.assign(effortData);
    setEffort(newEffort);
  }, [effortData]);

  const handleOnSave = () => {
    if (effort.id) {
      updateEffort(effort, onUpdateList, onClose);
    } else {
      createEffort(effort, onUpdateList, onClose);
    }
  };

  useEffect(() => {
    if (!effort) return;
    let validated = true;
    const date = effort?.date;
    const start = effort?.start;
    const end = effort?.end;

    if (!date || !start || !end) {
      validated = false;
    }
    setDataComplete(validated);
  }, [effort]);

  const title = effort?.id ? 'Editar esfuerzo' : 'Agregar esfuerzo';

  return (
    <ModalComponent
      open={true}
      title={title}
      element={effort}
      onClose={onClose}
      onConfirm={handleOnSave}
      disabled={!dataComplete}
    >
      <div className="effortsModal">
        <>
          <div>
            <MUIDateField
              id="date"
              name="date"
              label="Fecha planeada de inicio"
              value={effort?.date}
              onChange={(data) => inputChange(setEffort, data)}
            />
          </div>
          <div className="effortsModal_row">
            <MUITextField
              label="Inicio"
              defaultValue={effort?.start}
              type="time"
              name="start"
              onChange={(data) => inputChange(setEffort, data)}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300 // 5 min
              }}
            />
            <MUITextField
              label="Fin"
              defaultValue={effort?.end}
              type="time"
              name="end"
              onChange={(data) => inputChange(setEffort, data)}
              InputLabelProps={{
                shrink: true
              }}
              inputProps={{
                step: 300 // 5 min
              }}
            />
          </div>
        </>
      </div>
    </ModalComponent>
  );
};

export default EffortsModal;
