/** Import helpers section **/
import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  userCheckOutService,
  userCheckInService,
  userCheckInDeleteService,
  userCheckInEditService
} from '../../../../api/services/check-in-services';

import { colaboratorListSelectService } from '../../../../api/services/users-services';

const useModalActions = () => {
  const [colaborators, setColaborators] = useState([]);

  const getColaboratorsListSelect = useCallback(() => {
    colaboratorListSelectService()
      .then((response) => {
        if (response?.users) setColaborators(response?.users);
      })
      .catch((error) => {
        console.error('UserAddModal - Error getting: ', error);
      });
  }, []);

  const checkOut = useCallback((checkOutParams, onUpdateList, onClose) => {
    userCheckOutService(checkOutParams)
      .then((response) => {
        toast.success('Hora de salida registrada correctamente');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        console.error('Check out - Error getting: ', error);
      });
  }, []);

  const checkIn = useCallback((checkOutParams, onUpdateList, onClose) => {
    userCheckInService(checkOutParams)
      .then((response) => {
        toast.success('Hora de salida registrada correctamente');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        console.error('Check out - Error getting: ', error);
      });
  }, []);

  const deleteCheckIn = useCallback((check_id, onClose, onUpdateList) => {
    userCheckInDeleteService(check_id)
      .then((response) => {
        toast.success('Check in borrado correctamente');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        console.error('Check out - Error getting: ', error);
      });
  }, []);

  const checkInEdit = useCallback((checkInParams, onUpdateList, onClose) => {
    userCheckInEditService(checkInParams)
      .then((response) => {
        toast.success('Check in editado correctamente');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        console.error('Check in edit - Error getting: ', error);
      });
  }, []);

  return {
    colaborators,
    getColaboratorsListSelect,
    checkOut,
    checkIn,
    deleteCheckIn,
    checkInEdit
  };
};

export default useModalActions;
