/** Import react/libraries section **/
import React from 'react';

/** Import styles section **/
import './NodeStyles.scss';

/** Import resources section **/
import Typography from '@material-ui/core/Typography';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

/** Import component section **/

const NodeComponent = ({
  node,
  depth,
  isOpen,
  onToggle,
  onAdd,
  onEdit,
  onDelete
}) => {
  const { droppable, data, id } = node;
  const indent = depth * 1;

  const handleOnToggle = (e) => {
    e.stopPropagation();
    onToggle(id);
  };

  return (
    <div className="root" style={{ paddingInlineStart: indent }}>
      <div className={`expandIconWrapper ${isOpen ? 'isOpen' : ''}`}>
        {droppable && (
          <div onClick={handleOnToggle}>
            <ArrowRightIcon />
          </div>
        )}
      </div>
      <div className="inputWrapper">
        <Typography variant="body2">
          {node.text} ({data.planned_effort})
        </Typography>
        <div className="inputWrapper_actions">
          <AddCircleIcon
            className="g-row-action-button check"
            onClick={(e) => onAdd(node)}
          />
          <EditIcon
            className="g-row-action-button"
            onClick={(e) => onEdit(node)}
          />
          <DeleteIcon
            className="g-row-action-button delete"
            onClick={(e) => onDelete(id)}
          />
        </div>
      </div>
    </div>
  );
};

export default NodeComponent;
