/** Import helpers section **/
import { useState, useCallback } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/

import { addClientUserService } from '../../../../api/services/clients-services';
import { getUserClientsService } from '../../../../api/services/users-services';

const useModalActions = () => {
  const [users, setUsers] = useState(null);

  const addClientUser = (user, onClose, onUpdateList) => {
    addClientUserService(user)
      .then(() => {
        toast.success('Usuario agregado correctamente');
        onUpdateList();
        onClose();
      })
      .catch(() => {
        toast.warning('Error al agregar usuario');
      });
  };

  const getUserClients = useCallback(() => {
    getUserClientsService()
      .then((response) => {
        if (response?.users) {
          setUsers(response.users);
        }
      })
      .catch(() => {
        toast.warning('Client update error');
      });
  }, []);

  return {
    addClientUser,
    getUserClients,
    users
  };
};

export default useModalActions;
