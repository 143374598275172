/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';

/** Import helpers section **/
import moment from 'moment';
/** Import styles section **/
import './ActivitiesSummaryStyles.scss';

/** Import helpers sections **/
import {
  CHECK_IN_MODE,
  CHECK_EDIT_MODE,
  CHECK_OUT_MODE,
  CHECK_IN_LOCATION_MODE,
  CHECK_OUT_LOCATION_MODE
} from './constants';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import EditIcon from '@material-ui/icons/Edit';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckOutIcon from '@material-ui/icons/DirectionsRun';
import LocationOnIcon from '@material-ui/icons/LocationOn';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import MUIDateField from '../../components/inputs/MUIDateField';
import CheckInModal from './components/CheckInModal';
import CheckInLocationModal from './components/CheckInLocationModal';
import Modal from '../../components/Modal';

/** REDUX **/
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { Tooltip } from '@material-ui/core';

let currentDate = moment(new Date()).format('YYYY-MM-DD');
const checkInModel = {
  id: null,
  date: currentDate,
  check_in: '',
  check_out: '',
  check_in_comments: '',
  check_out_comments: ''
};

const UserCheckInPage = () => {
  let role = useSelector((store) => store.session.role);
  let checkOutRegistered = useSelector(
    (store) => store.session.profile.check_out_registered
  );
  let checkInRegistered = useSelector(
    (store) => store.session.profile.check_in_registered
  );
  const startOfMonth = moment().clone().startOf('month').format('YYYY-MM-DD');
  const endOfMonth = moment().format('YYYY-MM-DD');

  const [startDate, setStartDate] = useState(startOfMonth);
  const [endDate, setEndDate] = useState(endOfMonth);
  const [modalMode, setModalMode] = useState(null);
  const [selectedCheckIn, setSelectedCheckIn] = useState(null);
  const [lat, setLat] = useState(null);
  const [long, setLng] = useState(null);

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (!navigator.geolocation) {
      toast.warning('Activa la geolocalizacion para tu navegador');
    } else {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLat(position.coords.latitude);
          setLng(position.coords.longitude);
        },
        () => {
          toast.warning('Unable to retrieve your location');
        }
      );
    }
  };

  const { checkInData, loading, userCheckInList, userCheckInDelete } =
    usePageActions();

  const handleOnAddRegister = () => {
    setSelectedCheckIn(Object.assign({}, checkInModel));
    setModalMode(CHECK_IN_MODE);
  };

  const handleOnEditRegister = (row) => {
    setSelectedCheckIn(row);
    setModalMode(CHECK_EDIT_MODE);
  };

  const handleOnUpdateList = (row) => {
    let params = {
      start_date: startDate,
      end_date: endDate
    };
    userCheckInList(params);
  };

  useEffect(() => {
    if (role === 'colaborador' && checkOutRegistered === false) {
      toast.warning(
        'Necesitas registrar tus horas de salida del ultimo check in'
      );
    } else if (role === 'colaborador' && checkInRegistered === false) {
      toast.warning('Favor de registrar tu check in');
    }
  }, [role, checkOutRegistered, checkInRegistered]);

  useEffect(() => {
    if (startDate || endDate) {
      let params = {
        start_date: startDate,
        end_date: endDate
      };
      userCheckInList(params);
    }
  }, [startDate, endDate, userCheckInList]);

  const handleOnStartDateChange = (data) => {
    setStartDate(data.value);
  };

  const handleOnEndDateChange = (data) => {
    setEndDate(data.value);
  };

  const onClose = () => {
    setSelectedCheckIn(null);
    setModalMode(null);
  };

  const handleOnCheckOutRegister = (row) => {
    setSelectedCheckIn(row);
    setModalMode(CHECK_OUT_MODE);
  };

  const handelOnDeleteCheckIn = (row) => {
    setSelectedCheckIn(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    let params = {
      check_id: selectedCheckIn.id
    };
    userCheckInDelete(params, onClose, handleOnUpdateList);
  };

  const handleOnCheckInLocation = (row) => {
    setSelectedCheckIn(row);
    setModalMode(CHECK_IN_LOCATION_MODE);
  };

  const handleOnCheckOutLocation = (row) => {
    setSelectedCheckIn(row);
    setModalMode(CHECK_OUT_LOCATION_MODE);
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        {role === 'gerente' && (
          <EditIcon
            className="g-row-action-button edit"
            onClick={() => handleOnEditRegister(row)}
          />
        )}
        <CheckOutIcon
          className="g-row-action-button check"
          onClick={() => handleOnCheckOutRegister(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => handelOnDeleteCheckIn(row)}
        />
      </>
    );
  };

  const LocationsCell = ({ row }) => {
    return (
      <>
        {!!row?.check_in_latitude && row?.check_in_longitude && (
          <Tooltip
            title={'UBICACION DEL CHECK IN'}
            aria-label="'Ubicacion del checkin'"
          >
            <LocationOnIcon
              className="g-row-action-button check"
              onClick={() => handleOnCheckInLocation(row)}
            />
          </Tooltip>
        )}
        {!!row?.check_out_latitude && row?.check_out_longitude && (
          <Tooltip
            title={'UBICACION DEL CHECK OUT'}
            aria-label="'Ubicacion del checkin'"
          >
            <LocationOnIcon
              className="g-row-action-button checkout"
              onClick={() => handleOnCheckOutLocation(row)}
            />
          </Tooltip>
        )}
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        omit: true
      },
      {
        name: 'Usuario',
        selector: (row) => row?.name,
        sortable: true,
        center: true
      },
      {
        name: 'Fecha',
        selector: (row) => row?.date,
        sortable: true,
        center: true
      },
      {
        name: 'Entrada',
        selector: (row) => row?.check_in,
        sortable: true,
        center: true
      },
      {
        name: 'Salida',
        selector: (row) => row?.check_out,
        sortable: true,
        center: true
      },
      {
        name: 'Comentarios de entrada',
        selector: (row) => row?.check_in_comments,
        sortable: true,
        center: true
      },
      {
        name: ' Comentarios de salida',
        selector: (row) => row?.check_out_comments,
        sortable: true,
        center: true
      },
      {
        name: 'Acciones',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      },
      {
        name: 'Ubicaciones',
        cell: (row) => <LocationsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <div className="clients">
        <PageTitle title="Registro de horas" />
        <Paper className="g-page-header clients_header">
          <div className="activitiesSummary_selectors">
            <MUIDateField
              label="Fecha inicio"
              value={startDate}
              name="start"
              onChange={handleOnStartDateChange}
            />
            <MUIDateField
              label="Fecha fin"
              value={endDate}
              name="end"
              onChange={handleOnEndDateChange}
            />
          </div>
          <Button onClick={handleOnAddRegister} startIcon={<AddCircleIcon />}>
            Agregar nuevo registro
          </Button>
        </Paper>

        <DataTable
          data={checkInData}
          columns={tableColumns}
          loading={loading}
        />
      </div>
      {selectedCheckIn &&
        (modalMode === CHECK_IN_MODE ||
          modalMode === CHECK_OUT_MODE ||
          modalMode === CHECK_EDIT_MODE) && (
          <CheckInModal
            checkInData={selectedCheckIn}
            onClose={onClose}
            onUpdateList={handleOnUpdateList}
            mode={modalMode}
            latitude={lat}
            longitude={long}
          />
        )}

      {selectedCheckIn &&
        (modalMode === CHECK_IN_LOCATION_MODE ||
          modalMode === CHECK_OUT_LOCATION_MODE) && (
          <CheckInLocationModal
            checkInData={selectedCheckIn}
            onClose={onClose}
            mode={modalMode}
          />
        )}

      {selectedCheckIn && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={onClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar check in"
        >
          {'¿Estas seguro que quieres borrar el check in del dia ' +
            selectedCheckIn.date +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default UserCheckInPage;
