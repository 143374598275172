/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';

/** Import styles section **/
import './EffortsStyles.scss';

/** Import helpers section **/

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import EffortsModal from './components/EffortsModal';
import Modal from '../../components/Modal';
import DataTable from '../../components/DataTable';
import MUISelect from '../../components/inputs/MUISelect';
import PageTitle from '../../components/PageTitle';

const EffortsPage = () => {
  const [selectedEffort, setSelectedEffort] = useState(null);
  const [modalMode, setModalMode] = useState(null);

  const {
    loading,
    efforts,
    workpackage,
    activities,
    projects,
    selectedActivities,
    selectedProject,
    selectedWorkPackage,
    getProjects,
    deleteEfforts,
    handleOnUpdateList,
    handleOnProjectChange,
    handleOnWorkPackageChange,
    handleOnActivityChange
  } = usePageActions();

  useEffect(() => {
    getProjects();
  }, [getProjects]);

  const handelOnDeleteEfforts = (row) => {
    setSelectedEffort(row);
    setModalMode('delete');
  };

  const handleOnModalClose = () => {
    setSelectedEffort(null);
    setModalMode(null);
  };

  const handleOnDeleteConfirm = () => {
    deleteEfforts(selectedEffort.id, handleOnModalClose);
  };

  const handleOnEditEffort = (row) => {
    setSelectedEffort(row);
    setModalMode('effort');
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          className="g-row-action-button"
          onClick={() => handleOnEditEffort(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => handelOnDeleteEfforts(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Fecha',
        selector: 'date',
        sortable: true,
        center: true
      },
      {
        name: 'Inicio',
        selector: 'start',
        sortable: true,
        center: true
      },
      {
        name: 'Fin',
        selector: 'end',
        sortable: true,
        center: true
      },
      {
        name: 'Duración',
        selector: 'duration',
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <div className="efforts">
        <PageTitle title="Esfuerzos" />
        <Paper className="g-page-header efforts_header">
          <div className="efforts_selectors">
            <MUISelect
              label="Proyecto"
              defaultValue={selectedProject}
              items={projects}
              idSelector="id"
              selector="name"
              noSelectedLabel="Select"
              name="proyect_id"
              onChange={handleOnProjectChange}
            />
            {selectedProject ? (
              <MUISelect
                label="Paquete de trabajo"
                defaultValue={selectedWorkPackage}
                items={workpackage}
                idSelector="id"
                selector="title"
                noSelectedLabel="Select"
                name="workpackage_id"
                onChange={handleOnWorkPackageChange}
              />
            ) : (
              <MUISelect
                label="Paquete de trabajo"
                defaultValue={selectedWorkPackage}
                items={workpackage}
                idSelector="id"
                selector="title"
                noSelectedLabel="Select"
                name="workpackage_id"
                onChange={handleOnWorkPackageChange}
                disabled
              />
            )}
            {selectedWorkPackage ? (
              <MUISelect
                label="Actividad"
                defaultValue={selectedActivities}
                items={activities}
                idSelector="id"
                selector="title"
                noSelectedLabel="Select"
                name="activity_id"
                onChange={handleOnActivityChange}
              />
            ) : (
              <MUISelect
                label="Actividad"
                defaultValue={selectedActivities}
                items={activities}
                idSelector="id"
                selector="title"
                noSelectedLabel="Select"
                name="activity_id"
                onChange={handleOnActivityChange}
                disabled
              />
            )}
          </div>
        </Paper>

        <DataTable data={efforts} columns={tableColumns} loading={loading} />
      </div>
      {selectedEffort && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={handleOnModalClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar esfuerzo"
        >
          {'¿Estas seguro que quieres borrar el esfuerzo?'}
        </Modal>
      )}
      {selectedEffort && modalMode === 'effort' && (
        <EffortsModal
          effortData={selectedEffort}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
    </>
  );
};

export default EffortsPage;
