/** Import react/libraries section **/
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';

/** Import resources section **/
import Tooltip from '@material-ui/core/Tooltip';

/** Import styles section **/
import './MenuItemStyles.scss';

/** Import component section **/
// import MenuItemView from './MenuItemView';

export const MenuItemPage = ({ item }) => {
  let history = useHistory();
  let menuOpen = useSelector((store) => store.globals.menuOpen);

  const handleOnClick = (e) => {
    history.push(item?.url);
  };

  const onClick = item?.url ? handleOnClick : null;
  const Icon = item?.icon;

  return (
    // <MenuItemView
    //
    //   label={}
    //   onClick={}
    //   menuOpen={menuOpen}
    // />

    <div className="menuitem" onClick={onClick}>
      {menuOpen ? (
        <>
          <Icon className="menuitem_icon" />
          <span className="menuitem_label">{item?.label}</span>
        </>
      ) : (
        <>
          <Tooltip
            title={item?.label}
            aria-label={item?.label}
            placement="right"
          >
            <Icon className="menuitem_icon" />
          </Tooltip>
        </>
      )}
    </div>
  );
};

export default MenuItemPage;
