import { useCallback } from 'react';
import { useState } from 'react';

const usePageFilters = () => {
  const [filteredUsers, setFilteredUsers] = useState([]);

  const filterList = useCallback((filterVal, data) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...data];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = data.filter((item) => {
        const field1 = item.first_name?.toString().toLowerCase();
        const field2 = item.last_name?.toString().toLowerCase();
        const field3 = item.email?.toString().toLowerCase();
        const field4 = item.role_title?.toString().toLowerCase();

        return (
          field1.match(filteringText) ||
          field2.match(filteringText) ||
          field3.match(filteringText) ||
          (field4.match(filteringText) && true)
        );
      });
    }

    setFilteredUsers(filteredItems);
  }, []);

  return {
    filterList,
    filteredUsers
  };
};

export default usePageFilters;
