/** Import react/libraries section **/
import React, { useEffect, useState } from 'react';

/** Import helpers section **/
import { inputChange } from '../../../../utils/formUtils';

/** Import syles section **/
import './ProjectModalStyles.scss';

/** Import component section **/
import useModalActions from '../hooks/useModalActions';
import ModalComponent from '../../../../components/Modal';
import MUITextField from '../../../../components/inputs/MUITextField';
import MUISelect from '../../../../components/inputs/MUISelect';
import MUIDateField from '../../../../components/inputs/MUIDateField';
import ColorSelect from '../../../../components/ColorSelect';

const ProjectModal = ({
  projectData,
  clients,
  projectStatus,
  projectTypes,
  onClose,
  onUpdateList
}) => {
  const [project, setProject] = useState(null);
  const [dataComplete, setDataComplete] = useState(null);

  const { updateProject, createProject } = useModalActions();

  useEffect(() => {
    const newProject = Object.assign(projectData);
    setProject(newProject);
  }, [projectData]);

  useEffect(() => {
    if (!project) return;
    let validated = true;
    const name = project?.name?.toString().trim();
    const code = project?.code?.toString().trim();
    const planned_effort = project?.planned_effort?.toString().trim();
    const project_color = project?.project_color?.toString().trim();
    const client_id = project?.client_id;
    const project_status_id = project?.project_status_id;
    const project_type_id = project?.project_type_id;
    if (
      name === '' ||
      code === '' ||
      planned_effort === '' ||
      project_color === '' ||
      !client_id ||
      !project_status_id ||
      !project_type_id
    ) {
      validated = false;
    }

    setDataComplete(validated);
  }, [project]);

  const handleOnSave = () => {
    if (project.id) {
      updateProject(project, onUpdateList);
    } else {
      createProject(project, onUpdateList);
    }
    onClose();
  };

  const title = project?.id ? 'Editar proyecto' : 'Agregar proyecto';

  return (
    <ModalComponent
      open={true}
      title={title}
      onClose={onClose}
      onConfirm={handleOnSave}
      disabled={!dataComplete}
      width={800}
    >
      <div className="projectsModal">
        <MUITextField
          label="Código"
          defaultValue={project?.code}
          type="text"
          name="code"
          onChange={(data) => inputChange(setProject, data)}
        />
        <MUITextField
          id="name"
          label="Nombre"
          type="text"
          name="name"
          defaultValue={project?.name}
          onChange={(data) => inputChange(setProject, data)}
        />
        <MUISelect
          label="Tipo de proyecto"
          defaultValue={project?.project_type_id}
          items={projectTypes}
          idSelector="id"
          selector="type"
          noSelectedLabel="Select"
          name="project_type_id"
          onChange={(data) => inputChange(setProject, data)}
        />
        <MUISelect
          id="client"
          label="Cliente"
          defaultValue={project?.client_id}
          items={clients}
          idSelector="id"
          selector="name"
          noSelectedLabel="Select"
          name="client_id"
          onChange={(data) => inputChange(setProject, data)}
        />
        <MUITextField
          id="description"
          label="Descripción"
          defaultValue={project?.description}
          type="text"
          multiline
          name="description"
          onChange={(data) => inputChange(setProject, data)}
        />
        <MUISelect
          label="Estatus"
          defaultValue={project?.project_status_id}
          items={projectStatus}
          idSelector="id"
          selector="title"
          noSelectedLabel="Select"
          name="project_status_id"
          onChange={(data) => inputChange(setProject, data)}
        />
        <MUITextField
          label="Esfuerzo planeado"
          defaultValue={project?.planned_effort}
          type="number"
          name="planned_effort"
          onChange={(data) => inputChange(setProject, data)}
        />
        <MUIDateField
          id="planned_start_date"
          name="planned_start_date"
          label="Fecha inicio plan"
          value={project?.planned_start_date}
          onChange={(data) => inputChange(setProject, data)}
        />
        <MUIDateField
          id="planned_end_date"
          name="planned_end_date"
          label="Fecha fin plan"
          value={project?.planned_end_date}
          onChange={(data) => inputChange(setProject, data)}
        />
        <div className="projectsModal_color">
          <div className="projectsModal_color_label">Color:</div>
          <ColorSelect
            name="project_color"
            value={project?.project_color}
            onChange={(data) => inputChange(setProject, data)}
          />
        </div>
      </div>
    </ModalComponent>
  );
};

export default ProjectModal;
