const fieldsRules = {
  rfc: {
    required: true
  },
  name: {
    required: true
  }
};

export default fieldsRules;
