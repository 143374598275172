/** Import react/libraries section **/
import React, { useState } from 'react';

/** Import styles section **/
import './EventWrapperStyles.scss';

/** Import resources section **/

/** Import component section **/
import Popover from '@material-ui/core/Popover';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none'
  },
  paper: {
    padding: theme.spacing(1)
  }
}));

const EventWrapperComponent = ({ event, title }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = (event) => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className="eventWrapper"
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
    >
      <div className="eventWrapper_header">
        <span>{title}</span>
        <span>Duracion {event?.duration} hrs</span>
      </div>

      <div className="eventWrapper_content">
        <span className="eventWrapper_content_label">Actividad:</span>
        <span>{event?.activity?.activity_status?.title}</span>
        <span>Planeado {event?.activity?.planned_effort} hrs</span>
        <span>Completado {event?.activity?.completed_effort} hrs</span>
      </div>
      <div className="eventWrapper_content">
        <span className="eventWrapper_content_label">Entregable:</span>
        <span>{event?.workpackage?.title}</span>
        <span>Planeado {event?.workpackage?.planned_effort} hrs</span>
        <span>Completado {event?.workpackage?.workpackage_summary} hrs</span>
      </div>
      <div className="eventWrapper_content">
        <span className="eventWrapper_content_label">Proyecto:</span>
        <span>{event.project.name}</span>
      </div>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className="eventWrapper">
          <div className="eventWrapper_header">
            <span>{title}</span>
            <span>Duracion {event?.duration} hrs</span>
          </div>

          <div className="eventWrapper_content">
            <span className="eventWrapper_content_label">Actividad:</span>
            <span>{event?.activity?.activity_status?.title}</span>
            <span>Planeado {event?.activity?.planned_effort} hrs</span>
            <span>Completado {event?.activity?.completed_effort} hrs</span>
          </div>
          <div className="eventWrapper_content">
            <span className="eventWrapper_content_label">Entregable:</span>
            <span>{event?.workpackage?.title}</span>
            <span>Planeado {event?.workpackage?.planned_effort} hrs</span>
            <span>
              Completado {event?.workpackage?.workpackage_summary} hrs
            </span>
          </div>
          <div className="eventWrapper_content">
            <span className="eventWrapper_content_label">Proyecto:</span>
            <span>{event.project.name}</span>
          </div>
        </div>
      </Popover>
    </div>
  );
};

export default EventWrapperComponent;
