import request from '../api-connector';

export const getRolesService = () => {
  const serviceDef = {
    url: 'role/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getUsersService = () => {
  const serviceDef = {
    url: 'user/list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const getUserByIdService = ({ id }) => {
  const serviceDef = {
    url: 'user/read',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const addUserService = ({
  first_name,
  last_name,
  email,
  role_id,
  password,
  active,
  registered
}) => {
  const serviceDef = {
    url: 'user/create',
    method: 'post',
    params: {
      first_name,
      last_name,
      email,
      role_id,
      password,
      active,
      registered
    }
  };
  return request(serviceDef);
};

export const updateUserService = ({
  id,
  first_name,
  last_name,
  email,
  role_id,
  active,
  registered
}) => {
  const serviceDef = {
    url: 'user/update',
    method: 'post',
    params: { id, first_name, last_name, email, role_id, active, registered }
  };
  return request(serviceDef);
};

export const changePasswordService = ({ id, password }) => {
  const serviceDef = {
    url: 'user/update/password',
    method: 'post',
    params: {
      id,
      password
    }
  };
  return request(serviceDef);
};
export const changeActiveService = ({ id }) => {
  const serviceDef = {
    url: 'user/update/active',
    method: 'post',
    params: {
      id
    }
  };
  return request(serviceDef);
};

export const changeRegisterService = ({ id }) => {
  const serviceDef = {
    url: 'user/update/register',
    method: 'post',
    params: {
      id
    }
  };
  return request(serviceDef);
};

export const deleteUserService = ({ id }) => {
  const serviceDef = {
    url: 'user/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const getUserClientsService = () => {
  const serviceDef = {
    url: 'user/client-list',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const userCloseDayService = ({ close_day, open_day }) => {
  const serviceDef = {
    url: 'user/close-day',
    method: 'post',
    params: { close_day, open_day }
  };
  return request(serviceDef);
};

export const colaboratorListSelectService = () => {
  const serviceDef = {
    url: 'user/colaborators/list/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};

export const updateCloseDayService = ({ user_id, close_day }) => {
  const serviceDef = {
    url: 'user/update/close-day',
    method: 'post',
    params: { user_id, close_day }
  };
  return request(serviceDef);
};
