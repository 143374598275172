/** Import react/libraries section **/
import React, { useState } from 'react';

/** Import resources section **/
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

/** Import styles section **/
import './MenuStyles.scss';

/** Import helpers section **/
import MENU_ITEMS from './MENU_ITEMS';
import { MenuAccess } from '../../access/AccessControl';

/** Import component section **/
// import MenuView from './MenuView';
import MenuItem from './MenuItem';

export const MenuPage = () => {
  const [expanded, setExpanded] = useState(null);

  const Accordion = withStyles({
    root: {
      boxShadow: 'none !important',
      backgroundColor: 'transparent !important',
      '&:not(:last-child)': {
        borderBottom: 0
      },
      '&:before': {
        display: 'none'
      },
      '&$expanded': {
        margin: 'auto'
      }
    },
    expanded: {}
  })(MuiAccordion);

  const AccordionSummary = withStyles({
    root: {
      backgroundColor: 'rgba(0, 0, 0, .05)',
      borderBottom: '1px solid rgba(0, 0, 0, .125)',
      minHeight: 40,
      height: 40,
      justifyContent: 'flex-start !important',
      padding: '0 10px 0 0',
      margin: '0',
      '&$expanded': {
        minHeight: 40
      }
    },
    content: {
      padding: '0 10px 0 0',
      '&$expanded': {
        margin: '0'
      }
    },
    expandIcon: {
      padding: '5px',
      color: 'rgba(255, 255, 255, .97)',
      marginRight: '-4px',
      transform: 'rotate(-90deg)',
      '&$expanded': {
        transform: 'rotate(0deg)'
      }
    },
    expanded: {}
  })(MuiAccordionSummary);

  const AccordionDetails = withStyles((theme) => ({
    root: {
      padding: ' 0 0 0 10px !important',
      display: 'flex',
      flexDirection: 'column'
    }
  }))(MuiAccordionDetails);

  const MenuAccordion = ({ data, expanded, handleOnChange }) => {
    return (
      <Accordion expanded={expanded} onChange={handleOnChange(data?.id)}>
        <AccordionSummary
          id={data?.id}
          expandIcon={<ExpandMoreIcon />}
          aria-controls={'menu-item-content-' + data?.id}
        >
          <MenuItem item={data} />
        </AccordionSummary>
        <AccordionDetails>
          <>
            {data?.items.map((subitem, sub_index) => (
              <MenuAccess key={sub_index} permissionsRequest={subitem?.id}>
                <div className="mainmenu_item">
                  <MenuItem item={subitem} />
                </div>
              </MenuAccess>
            ))}
          </>
        </AccordionDetails>
      </Accordion>
    );
  };

  const handleOnChange = (id) => (event, newExpanded) => {
    setExpanded(newExpanded ? id : null);
  };

  return (
    <>
      <div className="mainmenu">
        {MENU_ITEMS?.map((item, index) => (
          <MenuAccess key={index} permissionsRequest={item?.id}>
            {item?.items && item?.items.length > 0 ? (
              <MenuAccordion
                data={item}
                expanded={expanded === item?.id}
                onChange={handleOnChange}
              />
            ) : (
              <div className="mainmenu_item">
                <MenuItem item={item} />
              </div>
            )}
          </MenuAccess>
        ))}
      </div>
    </>
  );
};

export default MenuPage;
