/** Import react/libraries section **/
import { configureStore } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import React from 'react';

import rootReducer from './rootReducer';

// Create root and apply middleware.
const store = configureStore({
  reducer: rootReducer
});

const StoreRoot = ({ children }) => (
  <Provider store={store}>{children}</Provider>
);

export default StoreRoot;
