const fieldsRules = {
  first_name: {
    required: true
  },
  last_name: {
    required: true
  },
  email: {
    required: true
  },
  role_id: {
    required: true
  }
};

export default fieldsRules;
