/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import {
  addEffortService,
  updateEffortService
} from '../../../../api/services/efforts-services';

const useModalActions = () => {
  const createEffort = (effort, onUpdateList, onClose) => {
    addEffortService(effort)
      .then((response) => {
        toast.success('Effort created');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Effort create error');
      });
  };

  const updateEffort = (effort, onUpdateList, onClose) => {
    updateEffortService(effort)
      .then(() => {
        toast.success('Effort updated');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Effort update error');
      });
  };

  return {
    updateEffort,
    createEffort
  };
};

export default useModalActions;
