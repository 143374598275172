/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';

/** Import styles section **/
import './LiberateActivitiesStyles.scss';

/** Import resources section **/
import Paper from '@material-ui/core/Paper';
import EditIcon from '@material-ui/icons/Edit';
import AddButton from '../../components/AddButton';

/** Import component section **/
import usePageActions from './hooks/usePageActions';
import usePageFilters from './hooks/usePageFilters';
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import FilterBy from '../../components/FilterBy';
import LiberateActivitiesModal from './components/LiberateActivitiesModal';
import MUIDateField from '../../components/inputs/MUIDateField';

const LiberateActivitiesPage = () => {
  const [filterText, setfilterText] = useState(null);
  const [userData, setUserData] = useState({});
  const [modalMode, setModalMode] = useState('');

  const {
    liberateActivities,
    nextBlockDate,
    currentBlockDate,
    loading,
    getLiberateActivitiesList,
    handleOnChangeDate,
    blockDay
  } = usePageActions();

  const { filterList, filteredActivities } = usePageFilters();

  useEffect(() => {
    getLiberateActivitiesList();
  }, [getLiberateActivitiesList]);

  useEffect(() => {
    filterList(filterText, liberateActivities);
  }, [filterText, liberateActivities, filterList]);

  const handleOnFilterCollaborators = (filterVal) => {
    setfilterText(filterVal);
  };

  const handleOnModalClose = () => {
    setUserData(null);
    setModalMode('');
  };

  const handleOnUpdateList = () => {
    getLiberateActivitiesList();
  };

  const handleOnEditLiberateActivity = (row) => {
    setUserData(row);
    setModalMode('liberate');
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          className="g-row-action-button"
          onClick={() => handleOnEditLiberateActivity(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Colaborador',
        selector: (row) => row?.name,
        sortable: true,
        center: true
      },
      {
        name: 'Ultima fecha de cierre',
        selector: (row) => row?.close_day,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <div className="clients">
        <PageTitle title="Liberación de actividades" />
        <Paper className="g-page-header liberateActivities_header">
          <FilterBy width="40%" onChange={handleOnFilterCollaborators} />
          <div className="liberateActivities_selectors">
            <MUIDateField
              label="Actual fecha de bloqueo"
              value={currentBlockDate}
              name="current_block_day"
              onChange={handleOnChangeDate}
              disabled={true}
            />
            <MUIDateField
              label="Editar Fecha de bloqueo"
              value={nextBlockDate}
              name="next_block_day"
              onChange={handleOnChangeDate}
            />
            <AddButton
              label={'Actualizar fecha de bloqueo'}
              onClick={blockDay}
              disabled={!nextBlockDate}
            />
          </div>
        </Paper>

        <DataTable
          data={filteredActivities}
          columns={tableColumns}
          loading={loading}
        />
      </div>
      {userData && modalMode === 'liberate' && (
        <LiberateActivitiesModal
          userData={userData}
          onClose={handleOnModalClose}
          onUpdateList={handleOnUpdateList}
        />
      )}
    </>
  );
};

export default LiberateActivitiesPage;
