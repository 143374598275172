/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/
import { updateCloseDayService } from '../../../../api/services/users-services';

const useModalActions = () => {
  const updateCloseDay = (user, onUpdateList, onClose) => {
    updateCloseDayService(user)
      .then(() => {
        toast.success('Close day update');
        onUpdateList();
        onClose();
      })
      .catch((error) => {
        toast.error('Close day update error');
      });
  };

  return {
    updateCloseDay
  };
};

export default useModalActions;
