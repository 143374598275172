/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/

import {
  getClientUsersService,
  deleteClientUserService
} from '../../../api/services/clients-services';
const usePageActions = () => {
  const [loading, setLoading] = useState(false);
  const [clientUsers, setClientUsers] = useState([]);
  const [title, setTitle] = useState('');

  const getClientUser = useCallback((clientId) => {
    getClientUsersService({ id: clientId })
      .then((response) => {
        if (response.users) {
          setClientUsers(response.users);
          setLoading(true);
        }
        if (response?.client) {
          setTitle('Usuarios del cliente ' + response.client);
        }
      })
      .catch(() => {
        toast.warning('Error getting client list');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const deleteClientUser = (id, onUpdateList, onClose) => {
    deleteClientUserService(id)
      .then(() => {
        toast.success('Usuario eliminado correctamente');
        onUpdateList();
        onClose();
      })
      .catch(() => {
        toast.warning('Client delete error');
      });
  };

  return {
    clientUsers,
    title,
    loading,
    getClientUser,
    deleteClientUser
  };
};

export default usePageActions;
