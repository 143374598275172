import request from '../api-connector';

export const getProjectsLightService = () => {
  const serviceDef = {
    url: 'project/list/select',
    method: 'get',
    params: null
  };
  return request(serviceDef);
};
export const getWorkPackageLightService = ({ project_id }) => {
  const serviceDef = {
    url: 'workpackage/list/select',
    method: 'get',
    params: { project_id }
  };
  return request(serviceDef);
};

export const getActivitiesLightService = ({ workpackage_id }) => {
  const serviceDef = {
    url: 'activity/list/select',
    method: 'get',
    params: { workpackage_id }
  };
  return request(serviceDef);
};

export const getEffortsService = ({ activity_id }) => {
  const serviceDef = {
    url: 'effort/list',
    method: 'get',
    params: { activity_id }
  };
  return request(serviceDef);
};
export const getEffortsByUserService = ({ start, end }) => {
  const serviceDef = {
    url: 'effort/list/calendar',
    method: 'get',
    params: { start, end }
  };
  return request(serviceDef);
};

export const getEffortsByIdService = ({ id }) => {
  const serviceDef = {
    url: 'effort/read',
    method: 'get',
    params: { id }
  };
  return request(serviceDef);
};

export const deleteEffortsService = ({ id }) => {
  const serviceDef = {
    url: 'effort/delete',
    method: 'post',
    params: { id }
  };
  return request(serviceDef);
};

export const addEffortService = ({ date, start, end, activity_id }) => {
  const serviceDef = {
    url: 'effort/create',
    method: 'post',
    params: { date, start, end, activity_id }
  };
  return request(serviceDef);
};

export const addEffortCalendarService = ({ date, start, end, activity_id }) => {
  const serviceDef = {
    url: 'effort/create/calendar',
    method: 'post',
    params: { date, start, end, activity_id }
  };
  return request(serviceDef);
};

export const updateEffortService = ({ id, date, start, end, activity_id }) => {
  const serviceDef = {
    url: 'effort/update',
    method: 'post',
    params: { id, date, start, end, activity_id }
  };
  return request(serviceDef);
};
export const dragUpdateEffortService = ({ id, start, end }) => {
  const serviceDef = {
    url: 'effort/update/dnd',
    method: 'post',
    params: { id, start, end }
  };
  return request(serviceDef);
};
