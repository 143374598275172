import React, { useState, useEffect } from 'react';

/** Import syles section **/
import './CheckInModalStyles.scss';

/** Import helpers section **/
import { inputChange } from '../../../../utils/formUtils';
import { MANAGER_ROLE } from '../../../../config/APP_CONFIGS';
import {
  CHECK_IN_MODE,
  CHECK_OUT_MODE,
  CHECK_EDIT_MODE
} from '../../constants';

/** Import component section **/
import ModalComponent from '../../../../components/Modal';
import useModalActions from '../hooks/useModalActions';
import MUIDateField from '../../../../components/inputs/MUIDateField';
import MUITextField from '../../../../components/inputs/MUITextField';
import MUISelect from '../../../../components/inputs/MUISelect';

/** REDUX **/
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const CheckInModal = ({
  checkInData,
  onClose,
  onUpdateList,
  mode,
  latitude,
  longitude
}) => {
  let userId = useSelector((store) => store.session.profile?.id);
  let role = useSelector((store) => store.session.role);
  console.log(latitude, longitude);
  const [selectedColaborator, setSelectedColaborator] = useState(null);
  const [userCheckData, setUserCheckData] = useState(null);

  const {
    colaborators,
    getColaboratorsListSelect,
    checkIn,
    checkOut,
    checkInEdit
  } = useModalActions();

  useEffect(() => {
    const newData = Object.assign(checkInData);
    setUserCheckData(newData);
  }, [checkInData]);

  useEffect(() => {
    if (role === MANAGER_ROLE) {
      getColaboratorsListSelect();
    }
  }, [role, getColaboratorsListSelect]);

  const handleOnSaveCheckOut = () => {
    if (!(latitude && longitude)) {
      toast('No ubicacion registrada');
      return;
    }
    console.log(latitude, longitude);
    if (mode === CHECK_IN_MODE) {
      let params = {
        check_id: userCheckData?.id,
        user_id: role === MANAGER_ROLE ? selectedColaborator : userId,
        date: userCheckData?.date,
        entry_time: userCheckData?.check_in,
        check_in_comments: userCheckData?.check_in_comments,
        latitude: latitude,
        longitude: longitude
      };
      checkIn(params, onUpdateList, onClose);
    } else if (mode === CHECK_EDIT_MODE) {
      let params = {
        check_id: userCheckData?.id,
        user_id: userId,
        date: userCheckData?.date,
        departure_time: userCheckData?.check_out,
        entry_time: userCheckData?.check_in,
        check_out_comments: userCheckData?.check_out_comments,
        check_in_comments: userCheckData?.check_in_comments
      };
      checkInEdit(params, onUpdateList, onClose);
    } else {
      let params = {
        check_id: userCheckData?.id,
        user_id: userId,
        date: userCheckData?.date,
        departure_time: userCheckData?.check_out,
        check_out_comments: userCheckData?.check_out_comments,
        latitude: latitude,
        longitude: longitude
      };
      checkOut(params, onUpdateList, onClose);
    }
  };

  const handleOnColaboratorChange = (data) => {
    const colaboratorId = data.value;
    setSelectedColaborator(colaboratorId);
  };

  const selectedColaboratorView = (
    <MUISelect
      label="Colaborador"
      defaultValue={selectedColaborator}
      items={colaborators}
      idSelector="id"
      selector="name"
      noSelectedLabel="Select"
      name="colaborator"
      onChange={handleOnColaboratorChange}
    />
  );

  const selectedDateView = (
    <MUIDateField
      id="date"
      label="Fecha"
      value={userCheckData?.date}
      name="date"
      onChange={(data) => inputChange(setUserCheckData, data)}
    />
  );

  const checkInTime = (
    <MUITextField
      label="Inicio de actividades"
      defaultValue={userCheckData?.check_in}
      type="time"
      name="check_in"
      onChange={(data) => inputChange(setUserCheckData, data)}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        step: 300 // 5 min
      }}
    />
  );

  const checkOutTime = (
    <MUITextField
      label="Fin de actividades"
      defaultValue={userCheckData?.check_out}
      type="time"
      name="check_out"
      onChange={(data) => inputChange(setUserCheckData, data)}
      InputLabelProps={{
        shrink: true
      }}
      inputProps={{
        step: 300 // 5 min
      }}
    />
  );

  const checkInComments = (
    <MUITextField
      label="Comentarios de entrada"
      defaultValue={userCheckData?.check_in_comments}
      type="text"
      name="check_in_comments"
      onChange={(data) => inputChange(setUserCheckData, data)}
    />
  );

  const checkOutComments = (
    <MUITextField
      label="Comentarios de salida"
      defaultValue={userCheckData?.check_out_comments}
      type="text"
      name="check_out_comments"
      onChange={(data) => inputChange(setUserCheckData, data)}
    />
  );

  const title =
    mode === CHECK_EDIT_MODE
      ? 'Edicion'
      : mode === CHECK_IN_MODE
      ? 'Check in'
      : mode === CHECK_OUT_MODE
      ? 'Check out'
      : '';

  return (
    <ModalComponent
      open={true}
      title={title}
      element={checkInData}
      onClose={onClose}
      onConfirm={handleOnSaveCheckOut}
    >
      <div className="checkInModalStyles">
        {/* show selected colaborator by admin */}
        {role === MANAGER_ROLE && !userCheckData?.id && selectedColaboratorView}
        {selectedDateView}
        {mode === CHECK_IN_MODE && (
          <>
            {checkInTime}
            {checkInComments}
          </>
        )}

        {mode === CHECK_OUT_MODE && (
          <>
            {checkOutTime}
            {checkOutComments}
          </>
        )}

        {mode === CHECK_EDIT_MODE && role === MANAGER_ROLE && (
          <>
            {checkInTime}
            {checkOutTime}
            {checkInComments}
            {checkOutComments}
          </>
        )}
      </div>
    </ModalComponent>
  );
};

export default CheckInModal;
