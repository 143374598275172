/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getProjectsSelectService,
  getWorkPackagesByProjectService,
  deleteWorkPackageService,
  updateWorkPackagetreeService
} from '../../../api/services/workpackage-services';

const usePageActions = () => {
  const [projects, setProjects] = useState([]);
  const [workPackages, setWorkPackages] = useState([]);

  const getProjects = useCallback(() => {
    getProjectsSelectService()
      .then((response) => {
        if (response?.projects) setProjects(response?.projects);
      })
      .catch((error) => {
        console.error('UserAddModal - Error getting: ', error);
      });
  }, []);

  const getWorkPackages = (projectId) => {
    if (!projectId) {
      setWorkPackages([]);
      return;
    }
    getWorkPackagesByProjectService({ project_id: projectId })
      .then((response) => {
        setWorkPackages(response?.workpackages);
      })
      .catch((error) => {
        console.error(
          'permissionsComponent - Error requesting permissions list: ',
          error
        );
      });
  };

  const updateTree = (dragSourceId, dropTargetId, selectedProject) => {
    updateWorkPackagetreeService({ dragSourceId, dropTargetId })
      .then((response) => {
        toast.success('Work Package tree updated');
        getWorkPackages(selectedProject);
      })
      .catch((error) => {
        toast.error('Work Package update tree error');
      });
  };

  const deleteWorkPackage = (id, selectedProject, handleOnModalClose) => {
    deleteWorkPackageService({ id: id })
      .then(() => {
        getWorkPackages(selectedProject);
        handleOnModalClose();
        toast.success('Paquete de trabajo borrado');
      })
      .catch((error) => {
        toast.error('Error, no se puede borrar el paquete de trabajo.');
      });
  };

  return {
    projects,
    workPackages,
    getProjects,
    getWorkPackages,
    updateTree,
    deleteWorkPackage
  };
};

export default usePageActions;
