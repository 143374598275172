/** Import react/libraries section **/
import React, { useEffect, useState, useMemo } from 'react';
import { useParams } from 'react-router-dom';

/* impor resources section */
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';

/** Import styles section **/
import './ClientsStyles.scss';

/** Import component section **/
import ClientUsersModal from './components/ClientUsersModal';
import Modal from '../../components/Modal';
import usePageActions from './hooks/usePageActions';
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';

const clientUsersModel = {
  id: null,
  user_id: '',
  client_id: ''
};

const ClientUsersPage = () => {
  const [selectedClientUser, setSelectedClientUser] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const params = useParams();
  const clientIdParam = params['id'];

  const { clientUsers, title, loading, getClientUser, deleteClientUser } =
    usePageActions();

  const onClose = () => {
    setSelectedClientUser(null);
    setModalMode(null);
  };

  const onUpdateList = () => {
    getClientUser(clientIdParam);
  };

  useEffect(() => {
    getClientUser(clientIdParam);
  }, [clientIdParam, getClientUser]);

  const handleOnAddClientUser = () => {
    setSelectedClientUser(Object.assign({}, clientUsersModel));
    setModalMode('client');
  };

  const handelOnDeleteClientUser = (row) => {
    setSelectedClientUser(row);
    setModalMode('delete');
  };

  const handleOnDeleteConfirm = () => {
    deleteClientUser(selectedClientUser.id, onUpdateList, onClose);
  };

  const ActionsCell = ({ row }) => {
    return (
      <>
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => handelOnDeleteClientUser(row)}
        />
      </>
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre',
        selector: (row) => row?.user?.first_name + ' ' + row?.user?.last_name,
        sortable: true,
        center: true
      },
      {
        name: 'Email',
        selector: (row) => row?.user?.email,
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <div className="clients">
        <PageTitle title={title} />
        <Paper className="g-page-header clients_header">
          <Button
            className="clients_header_button"
            onClick={handleOnAddClientUser}
            startIcon={<AddCircleIcon />}
          >
            Agregar nuevo usuario
          </Button>
        </Paper>

        <DataTable
          data={clientUsers}
          columns={tableColumns}
          loading={loading}
        />
      </div>

      {selectedClientUser && modalMode === 'client' && (
        <ClientUsersModal
          clientUserData={selectedClientUser}
          onClose={onClose}
          onUpdateList={onUpdateList}
          clientId={clientIdParam}
        />
      )}
      {selectedClientUser && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={onClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Cliente"
        >
          {'¿Estas seguro que quieres borrar al usuario ' +
            selectedClientUser?.user?.first_name +
            ' ' +
            selectedClientUser?.user?.last_name +
            '?'}
        </Modal>
      )}
    </>
  );
};

export default ClientUsersPage;
