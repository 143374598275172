/** Import syles section **/
import './ActivityEffortsStyles.scss';

/** Import component section **/
import ModalComponent from '../../../../components/Modal';

const ActivityEffortsModal = ({ efforts, activityTitle, onClose }) => {
  return (
    <ModalComponent
      open={true}
      title={'Esfuerzos de la actividad: ' + activityTitle}
      element={efforts}
      onClose={onClose}
      width={750}
    >
      <div className="ActivityEffortsStyles">
        <div className="ActivityEffortsStyles_table">
          <span className="ActivityEffortsStyles_header">Esfuerzo</span>
          <span className="ActivityEffortsStyles_header">Fecha</span>
          <span className="ActivityEffortsStyles_header">Inicio</span>
          <span className="ActivityEffortsStyles_header">Fin</span>
          <span className="ActivityEffortsStyles_header">Duración</span>
        </div>
        {efforts.map((effort, index) => (
          <div key={'wo_' + index} className="ActivityEffortsStyles_table">
            <span>{index + 1}</span>
            <span>{effort?.date}</span>
            <span>{effort?.start_hour}</span>
            <span>{effort?.end_hour}</span>
            <span>{effort?.duration}</span>
          </div>
        ))}
      </div>
    </ModalComponent>
  );
};

export default ActivityEffortsModal;
