import React from 'react';
import { withStyles } from '@material-ui/core/styles';

import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    backgroundColor: 'white',
    padding: '10px 10px 0 10px'
  },
  dialogTitle: {
    margin: 0,
    padding: 0,
    height: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: '5px'
  },
  title: {
    fontSize: '20px',
    paddingLeft: '10px'
  },
  closeButton: {
    color: theme.palette.grey[500],
    padding: '5px'
  },
  divider: {
    marginBottom: '10px'
  }
});

const MUIDialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <div className={classes.root}>
      <DialogTitle disableTypography className={classes.dialogTitle} {...other}>
        <Typography className={classes.title}>{children}</Typography>
        {onClose ? (
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
      <Divider className={classes.divider} />
    </div>
  );
});

export default MUIDialogTitle;
