/** Import react/libraries section **/
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useHistory } from 'react-router-dom';

/* import helpers section */
import { CLIENT_USERS } from '../../config/URL_ROUTES';

/* impor resources section */
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import ImageIcon from '@material-ui/icons/Image';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';
import PersonAddIcon from '@material-ui/icons/PersonAdd';

/** Import styles section **/
import './ClientsStyles.scss';

/** Import component section **/
import ClientsModal from './components/ClientsModal';
import Modal from '../../components/Modal';
import DropFile from '../../components/DropFile';
import usePageActions from './hooks/usePageActions';
import PageTitle from '../../components/PageTitle';
import DataTable from '../../components/DataTable';
import FilterBy from '../../components/FilterBy';

const clientsModel = {
  id: null,
  name: '',
  log_url: '',
  rfc: ''
};

const ClientsPage = () => {
  const [clients, setClients] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedClient, setSelectedClient] = useState(null);
  const [modalMode, setModalMode] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const history = useHistory();

  const { getClientList, deleteClient, saveClientLogo } = usePageActions();

  const onClose = () => {
    setSelectedClient(null);
    setModalMode(null);
  };

  const getClients = useCallback(() => {
    setLoading(true);
    getClientList(setClients, setOriginalData);
    setLoading(false);
  }, [getClientList, setClients, setOriginalData]);

  const onUpdateList = useCallback(() => {
    getClients();
  }, [getClients]);

  useEffect(() => {
    getClients();
  }, [getClients]);

  const handleOnAddClient = () => {
    setSelectedClient(Object.assign({}, clientsModel));
    setModalMode('client');
  };

  const handelOnDeleteClient = (row) => {
    setSelectedClient(row);
    setModalMode('delete');
  };

  const handleOnEditClientUser = (row) => {
    history.push(CLIENT_USERS + '/' + row?.id);
  };

  const handleOnDeleteConfirm = () => {
    deleteClient({ id: selectedClient.id }, onClose, onUpdateList);
  };

  const handleOnEditClient = (row) => {
    setSelectedClient(row);
    setModalMode('client');
  };

  const handleOnFilterClients = (filterVal) => {
    let filteredItems;

    if (!filterVal || filterVal.length === 0) {
      filteredItems = [...originalData];
    } else {
      const filteringText = filterVal?.toLowerCase();

      filteredItems = originalData.filter((item) => {
        const field1 = item.name?.toString().toLowerCase();
        const field2 = item.rfc?.toString().toLowerCase();

        return (
          field1.match(filteringText) || (field2.match(filteringText) && true)
        );
      });
    }

    setClients(filteredItems);
  };
  const handleOnChangeImage = (row) => {
    setSelectedClient(row);
    setModalMode('logo');
  };

  const handleOnSaveClientLogoModal = (files) => {
    const data = {
      file: files[0],
      id: selectedClient.id
    };
    saveClientLogo(data, onClose, onUpdateList);
  };
  const ActionsCell = ({ row }) => {
    return (
      <>
        <EditIcon
          className="g-row-action-button"
          onClick={() => handleOnEditClient(row)}
        />
        <DeleteIcon
          className="g-row-action-button delete"
          onClick={() => handelOnDeleteClient(row)}
        />
        <PersonAddIcon
          className="g-row-action-button"
          onClick={() => handleOnEditClientUser(row)}
        />
      </>
    );
  };
  const LogoCell = ({ row }) => {
    if (row.logo_url)
      return (
        <ImageIcon
          className="g-activeicon hasImage users_active"
          onClick={() => handleOnChangeImage(row)}
        />
      );
    return (
      <ImageOutlinedIcon
        className="g-activeicon users_active"
        onClick={() => handleOnChangeImage(row)}
      />
    );
  };

  const tableColumns = useMemo(
    () => [
      {
        name: 'Id',
        selector: 'id',
        sortable: true,
        center: true,
        omit: true
      },
      {
        name: 'Nombre',
        selector: 'name',
        sortable: true,
        center: true
      },
      {
        name: 'Logo',
        selector: 'logo_url',
        cell: (row) => <LogoCell row={row} />,
        sortable: true,
        center: true
      },
      {
        name: 'RFC',
        selector: 'rfc',
        sortable: true,
        center: true
      },
      {
        name: '',
        cell: (row) => <ActionsCell row={row} />,
        ignoreRowClick: true,
        allowOverflow: true,
        center: true
      }
    ],
    []
  );

  return (
    <>
      <div className="clients">
        <PageTitle title="Clientes" />
        <Paper className="g-page-header clients_header">
          <div className="users_header_filterby">
            <FilterBy onChange={handleOnFilterClients} />
          </div>
          <Button
            className="clients_header_button"
            onClick={handleOnAddClient}
            startIcon={<AddCircleIcon />}
          >
            Agregar nuevo cliente
          </Button>
        </Paper>

        <DataTable data={clients} columns={tableColumns} loading={loading} />
      </div>

      {selectedClient && modalMode === 'client' && (
        <ClientsModal
          clientData={selectedClient}
          onClose={onClose}
          onUpdateList={onUpdateList}
        />
      )}
      {selectedClient && modalMode === 'delete' && (
        <Modal
          open={true}
          onClose={onClose}
          onConfirm={handleOnDeleteConfirm}
          title="Borrar Cliente"
        >
          {'¿Estas seguro que quieres borrar el cliente ' +
            selectedClient.name +
            '?'}
        </Modal>
      )}
      {selectedClient && modalMode === 'logo' && (
        <DropFile
          open={true}
          title={'Subir logo del cliente'}
          url={selectedClient?.logo_url}
          onConfirm={handleOnSaveClientLogoModal}
          onClose={onClose}
          confirmText="Enviar"
          cancelText="Cancelar"
        ></DropFile>
      )}
    </>
  );
};

export default ClientsPage;
