/** Import helpers section **/
import { useCallback, useState } from 'react';
import { toast } from 'react-toastify';

/** Import services section **/
import {
  getPermissionsByRoleService,
  checkRolePermissionsService,
  getRolesService
} from '../../../api/services/permission-services';

const useActions = () => {
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(false);

  const getPermissions = useCallback((id) => {
    setLoading(true);
    getPermissionsByRoleService({ id })
      .then((response) => {
        setPermissions(response?.permissions);
      })
      .catch((error) => {
        console.error(
          'permissionsComponent - Error requesting permissions list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  const getRoles = useCallback(() => {
    getRolesService()
      .then((response) => {
        if (response?.roles) setRoles(response?.roles);
      })
      .catch((error) => {
        console.error('UserAddModal - Error getting: ', error);
      });
  }, []);

  const checkRolePermissions = (rolePermissions, handleOnUpdateList) => {
    setLoading(true);
    checkRolePermissionsService(rolePermissions)
      .then((response) => {
        toast.success('Permisos actualizados');
        handleOnUpdateList();
      })
      .catch((error) => {
        console.error(
          'permissionsComponent - Error requesting permissions list: ',
          error
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    roles,
    permissions,
    loading,
    getPermissions,
    getRoles,
    checkRolePermissions
  };
};

export default useActions;
