/** Import helpers section **/
import { toast } from 'react-toastify';

/** Import services section **/

import {
  addClientsService,
  updateClientsService
} from '../../../../api/services/clients-services';
const useModalActions = () => {
  const addClient = (client, onClose, onUpdateList) => {
    addClientsService(client, onClose, onUpdateList)
      .then(() => {
        toast.success('Client added correctly');
        onUpdateList();
        onClose();
      })
      .catch(() => {
        toast.warning('Client create error');
      });
  };
  const updateClient = (client, onClose, onUpdateList) => {
    updateClientsService(client, onClose, onUpdateList)
      .then(() => {
        toast.success('Client updated correctly');
        onUpdateList();
        onClose();
      })
      .catch(() => {
        toast.warning('Client update error');
      });
  };

  return {
    addClient,
    updateClient
  };
};

export default useModalActions;
